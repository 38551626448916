<template>
  <main-layout>
    <template slot="title">
      {{ $t('nav.account') }}
    </template>
    <div class="min-h-full">
      <div class="m-auto max-w-7xl md:flex md:space-x-10">
        <div class="flex-1">
          <locale-card></locale-card>
          <email-card v-if="user"></email-card>
          <information-card v-if="canManageSubscriptions"></information-card>
        </div>
        <div class="flex-1">
          <auth-card></auth-card>
          <two-factor-card></two-factor-card>
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>

import MainLayout from "@/components/Layout/MainLayout";
import InformationCard from "@/components/Account/InformationCard";
import AuthCard from "@/components/Account/AuthCard";
import LocaleCard from "@/components/Account/LocaleCard";
import {globalData} from "@/globalData";
import TwoFactorCard from "../components/Account/TwoFactorCard";
import EmailCard from "@/components/Account/EmailCard.vue";

export default {
  components: {
    EmailCard,
    TwoFactorCard,
    LocaleCard,
    AuthCard,
    InformationCard,
    MainLayout
  },
  mounted() {
    globalData.activeRoute = 'account';
  },
  computed: {
    user() {
      return globalData.user;
    },
    canManageSubscriptions() {
      return this.user && (this.user.account_owner || this.user.permissions.filter(p => p.type === 'subscriptions').length > 0)
    }
  }
}
</script>
