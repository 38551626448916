<template>
  <div class="shadow-md rounded-xl bg-white text-gray-700 mb-10">
    <h2 class="text-xl mb-4 font-bold px-6 border-b-2 pt-4 pb-4 border-gray-100">
      <slot name="title"></slot>
    </h2>

    <div class="leading-6 px-6 pb-6 pt-2">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>