<template>
  <div>
    <p class="mb-8">{{ $t('servers.stats.notice') }}</p>
    <div v-if="trafficDay.length < 2 && trafficWeek.length < 1" class="p-6 mb-5 font-bold bg-blue-200">
      <p>{{ $t('servers.stats.empty') }}</p>
    </div>
    <div v-else>
      <div class="flex" style="height: 300px;">
        <div class="flex-1" v-if="trafficDay.length">
          <h2 class="text-xl border-b border-gray-200 pb-2 mb-4 font-bold tracking-wider">{{ $t('servers.stats.title-day') }}</h2>
          <div class="mb-3">
            <traffic-chart v-if="trafficDay.length"
                           :chart-data="getChartData(trafficDay, 'HH')"></traffic-chart>
          </div>
        </div>
        <div class="flex-1">
          <h2 class="text-xl border-b border-gray-200 pb-2 mb-4 font-bold tracking-wider">{{ $t('servers.stats.title-week') }}</h2>
          <div class="mb-3">
            <traffic-chart v-if="trafficWeek.length" :chart-data="getChartData(trafficWeek, 'ddd')"></traffic-chart>
          </div>
        </div>
      </div>

      <h2 class="text-xl border-b border-gray-200 pb-2 mb-4 font-bold tracking-wider">{{ $t('servers.stats.title-month') }}</h2>
      <div class="mb-3">
        <traffic-chart v-if="trafficMonth.length" :chart-data="getChartData(trafficMonth, 'DD')"></traffic-chart>
      </div>
    </div>
  </div>
</template>

<script>
import {api} from "@/api";
import moment from "moment";
import TrafficChart from "@/components/Servers/Statistics/TrafficChart";

export default {
  components: {TrafficChart},
  props: {
    product: {
      required: true
    }
  },
  data() {
    return {
      trafficDay: [],
      trafficWeek: [],
      trafficMonth: []
    };
  },
  async mounted() {
    let response = await api.get('user/products/' + this.product.id + '/traffic');

    this.trafficDay = response.data.day;
    this.trafficWeek = response.data.week;
    this.trafficMonth = response.data.month;
  },
  methods: {
    getChartData(traffic, format) {
      // create a copy
      let events = [...traffic];
      return {
        labels: events.map(e => moment(e.created_at).format(format)),
        series: [
          events.map(e => e.in_bytes),
          events.map(e => e.out_bytes),
        ]
      }
    }
  }
}
</script>

<style scoped>

</style>