<template>
  <div>
    <div class="grid grid-cols-1 md:grid-cols-2 gap-10">
      <div>
        <h2 class="text-xl border-b border-gray-200 pb-2 mb-4 font-bold tracking-wider">
          {{ $t('servers.ac.title-credentials') }}</h2>
        <p class="text-gray-500 text-sm">{{ $t('servers.ac.credentials-description') }}</p>
        <div class="md:table mt-5">
          <div class="md:table-row bg-gray-100">
            <div class="md:table-cell px-4 py-2 font-bold">
              {{ $t('servers.ac.username') }}:
            </div>
            <div class="md:table-cell px-4 pb-2 font-mono">
              <span>{{ product.settings.initialLoginUser }}</span>
            </div>
          </div>
          <div class="md:table-row">
            <div class="md:table-cell px-4 py-2 font-bold">
              {{ $t('servers.ac.password') }}:
            </div>
            <div class="md:table-cell px-4 pb-2 font-mono">
              <span v-if="passwordShown">{{ product.settings.initialLoginPassword }}</span>
              <span v-else class="blurry-text cursor-pointer" @click="passwordShown = true">
                  {{ '*'.repeat(product.settings.initialLoginPassword.length) }}
                </span>
              <i class="fas cursor-pointer ml-1" :class="passwordShown ? 'fa-eye' : 'fa-eye-slash'"
                 @click.prevent="passwordShown = !passwordShown"></i>
            </div>
          </div>
          <div class="md:table-row bg-gray-100">
            <div class="md:table-cell px-4 py-2 font-bold">
              {{ $t('servers.ac.ip') }}:
            </div>
            <div class="md:table-cell px-4 pb-2 font-mono">
              <span>{{ product.settings.ipAddresses[0].ip }}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="product.type === 'mac'">
        <div class="flex border-b border-gray-200 pb-2 mb-4">
          <h2 class="text-xl font-bold tracking-wider flex-1">{{ $t('servers.ac.title-default') }}</h2>
          <a :href="$t('help-link') + 'access-your-mac#2-access-via-third-party-services'" target="_blank">
            <i class="far fa-question-circle text-2xl mr-2 text-oh-red"></i>
          </a>
        </div>
        <p class="text-gray-500 text-sm">{{ $t('servers.ac.default-description') }}</p>
        <div class="mt-10" v-if="usedOS === 'mac'">
          <div class="mb-8 text-center">
            <img src="../../assets/img/screen_sharing.png" class="w-32 m-auto mb-5">
            <oh-button @click="openScreenSharing()" type="primary">
              <span>{{ $t('servers.ac.button-screen-sharing-mac') }}</span>
            </oh-button>
          </div>
          <!--          <p class="mb-2">{{ $t('servers.ac.note') }}</p>-->
          <a class="text-oh-blue hover:underline font-bold"
             :href="$t('help-link') + 'access-your-mac#2-access-via-third-party-services'"
             target="_blank">{{ $t('servers.ac.note-guide') }} <i class="fas fa-external-link-alt ml-2"></i> </a>
        </div>
        <div class="mt-10" v-else>
          <div class="mb-8 text-center">
            <img src="../../assets/img/screen_sharing.png" class="w-32 m-auto mb-5">
            <a href="https://www.realvnc.com/en/connect/download/viewer/"
               class="block mb-4 text-oh-blue hover:underline font-bold" target="_blank">
              {{ $t('servers.ac.link-realvnc') }} <i class="fas fa-external-link-alt ml-2"></i>
            </a>
            <oh-button @click="openScreenSharing()" type="primary">
              <span>{{ $t('servers.ac.button-screen-sharing') }}</span>
            </oh-button>
          </div>
          <p class="mb-4">{{ $t('servers.ac.note-windows') }}</p>
          <a class="text-oh-blue hover:underline font-bold"
             :href="$t('help-link') + 'access-your-mac#2-access-via-screen-sharing'"
             target="_blank">{{ $t('servers.ac.note-guide') }} <i class="fas fa-external-link-alt ml-2"></i> </a>
        </div>
      </div>

      <div>
        <h2 class="text-xl border-b border-gray-200 pb-2 mb-4 font-bold tracking-wider">
          {{ $t('servers.ac.title-ssh') }}
        </h2>
        <p class="text-gray-500 text-sm">{{ $t('servers.ac.ssh-description') }}</p>
        <p class="mt-4">{{ $t('servers.ac.ssh-info') }}</p>
        <div class="bg-gray-900 text-white font-mono p-2 mt-4">
          ssh {{ product.settings.initialLoginUser }}@{{ product.settings.ipAddresses[0].ip }}
        </div>
        <p class="mt-4" v-if="product.settings.initialLoginUser !== 'root'">{{ $t('servers.ac.ssh-sudo') }}</p>
        <div class="bg-gray-900 text-white font-mono p-2 mt-4" v-if="product.settings.initialLoginUser !== 'root'">
          sudo su # switch to root user
        </div>
      </div>

      <div v-if="product.type === 'mac'">
        <h2 class="text-xl border-b border-gray-200 pb-2 mb-4 font-bold tracking-wider">{{
            $t('servers.ac.title-kvm')
          }}</h2>
        <p class="text-gray-500 text-sm">{{ $t('servers.ac.kvm-description') }}</p>

        <div class="text-center mt-10">
          <div class="mb-10 relative">
            <i class="fas fa-desktop text-9xl"></i>
            <span class="absolute left-0 right-0 top-9 text-2xl font-bold">KVM</span>
          </div>
          <oh-button @click="openVNC()" type="primary">
            {{ $t('servers.ac.remote') }}
          </oh-button>
          <div class="mt-3 text-gray-500 text-sm">
            {{ $t('servers.ac.popup-notice') }}<br/>
            {{ $t('servers.ac.popup-notice-2') }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="product.type === 'linux_root' && product.settings.capabilities.includes('vnc')" class="mt-10">
      <h2 class="text-xl border-b border-gray-200 pb-2 mb-4 font-bold tracking-wider">
        {{ $t('servers.ac.title-vnc') }}
      </h2>
      <div class="flex">
        <div class="text-center w-1/2 px-6">
          <div class="mb-10 relative">
            <i class="fas fa-desktop text-9xl"></i>
            <span class="absolute left-0 right-0 top-9 text-2xl font-bold">VNC</span>
          </div>
        </div>
        <div>
          <p class="text-gray-500 text-sm block mb-6">{{ $t('servers.ac.vnc-description') }}</p>
          <oh-button @click="openVNC()" type="primary">
            {{ $t('servers.ac.remote-linux') }}
          </oh-button>
          <div class="mt-3 text-gray-500 text-sm">
            {{ $t('servers.ac.popup-notice') }}<br/>
            {{ $t('servers.ac.popup-notice-2') }}
          </div>
        </div>
      </div>
    </div>
    <modal :show="showVncConnectModal" @close="showVncConnectModal = false">
      <template slot="title">
        {{ $t('servers.ac.modal.title') }}
      </template>
      {{ $t('servers.ac.modal.line-1') }}
      <div class="bg-gray-900 text-white font-mono p-2 my-4">
        {{ product.settings.ipAddresses[0].ip }}
      </div>
      <p>{{ $t('servers.ac.modal.line-2') }}</p>
      <p>{{ $t('servers.ac.modal.line-3') }}</p>
      <div class="mt-4 text-center">
        <oh-button @click="showVncConnectModal = false" type="primary">
          {{ $t('servers.ac.modal.button') }}
        </oh-button>
      </div>
    </modal>
  </div>
</template>

<script>
import OhButton from "@/components/OhButton";
import Modal from "@/components/Modal.vue";
import {globalData} from "@/globalData";

export default {
  components: {Modal, OhButton},
  props: {
    product: {
      required: true
    }
  },
  data() {
    return {
      passwordShown: false,
      showVncConnectModal: false
    };
  },
  methods: {
    openScreenSharing() {
      let userIp = this.product.settings.ipAddresses[0].ip;
      let iprDNS = this.product.settings.ipAddresses[0].rDNS;
      if (this.user._client_ip_protocol === 'ipv6' && iprDNS.includes('oakhost-customer.net')) {
        userIp = iprDNS;
      }

      let url = 'vnc://' + this.product.settings.initialLoginUser + '@' + userIp + ':5900';
      if (this.usedOS === 'windows') {
        url = 'com.realvnc.vncviewer.connect://' + userIp + ':5900';
      }

      this.launchUri(
          url,
          () => true,
          () => this.showVncConnectModal = true,
          () => this.showVncConnectModal = true
      );
    },
    openVNC() {
      window.open('/kvm/' + this.product.id + '?nosidebar=1', 'winname', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,width=1920,height=1080');
    },
    launchUri(uri, successCallback, noHandlerCallback, unknownCallback) {
      // https://gist.github.com/aaronk6/d801d750f14ac31845e8
      var parent, popup, iframe, timer, timeout, blurHandler, timeoutHandler, browser;

      function callback(cb) {
        if (typeof cb === 'function') cb();
      }

      function createHiddenIframe(parent) {
        var iframe;
        if (!parent) parent = document.body;
        iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        parent.appendChild(iframe);
        return iframe;
      }

      function removeHiddenIframe(parent) {
        if (!iframe) return;
        if (!parent) parent = document.body;
        parent.removeChild(iframe);
        iframe = null;
      }

      browser = {isChrome: false, isFirefox: false, isIE: false};

      if (window.chrome && !navigator.userAgent.match(/Opera|OPR\//)) {
        browser.isChrome = true;
      } else if (typeof InstallTrigger !== 'undefined') {
        browser.isFirefox = true;
      } else if ('ActiveXObject' in window) {
        browser.isIE = true;
      }

      // Proprietary msLaunchUri method (IE 10+ on Windows 8+)
      if (navigator.msLaunchUri) {
        navigator.msLaunchUri(uri, successCallback, noHandlerCallback);
      }
      // Blur hack (Chrome)
      else if (browser.isChrome) {
        blurHandler = function () {
          window.clearTimeout(timeout);
          window.removeEventListener('blur', blurHandler);
          callback(successCallback);
        };
        timeoutHandler = function () {
          window.removeEventListener('blur', blurHandler);
          callback(noHandlerCallback);
        };
        window.addEventListener('blur', blurHandler);
        timeout = window.setTimeout(timeoutHandler, 500);
        window.location.href = uri;
      }
      // Catch NS_ERROR_UNKNOWN_PROTOCOL exception (Firefox)
      else if (browser.isFirefox) {
        iframe = createHiddenIframe();
        try {
          // if we're still allowed to change the iframe's location, the protocol is registered
          iframe.contentWindow.location.href = uri;
          callback(successCallback);
        } catch (e) {
          if (e.name === 'NS_ERROR_UNKNOWN_PROTOCOL') {
            callback(noHandlerCallback);
          } else {
            callback(unknownCallback);
          }
        } finally {
          removeHiddenIframe();
        }
      }
      // Open popup, change location, check wether we can access the location after the change (IE on Windows < 8)
      else if (browser.isIE) {
        popup = window.open('', 'launcher', 'width=0,height=0');
        popup.location.href = uri;
        try {
          // Try to change the popup's location - if it fails, the protocol isn't registered
          // and we'll end up in the `catch` block.
          popup.location.href = 'about:blank';
          callback(successCallback);
          // The user will be shown a modal dialog to allow the external application. While
          // this dialog is open, we cannot close the popup, so we try again and again until
          // we succeed.
          timer = window.setInterval(function () {
            popup.close();
            if (popup.closed) window.clearInterval(timer);
          }, 500);
        } catch (e) {
          // Regain access to the popup in order to close it.
          popup = window.open('about:blank', 'launcher');
          popup.close();
          callback(noHandlerCallback);
        }
      }
      // No hack we can use, just open the URL in an hidden iframe and invoke `unknownCallback`
      else {
        iframe = createHiddenIframe();
        iframe.contentWindow.location.href = uri;
        window.setTimeout(function () {
          removeHiddenIframe(parent);
          callback(unknownCallback);
        }, 500);
      }
    }
  },
  computed: {
    user() {
      return globalData.user;
    },
    usedOS() {
      const platform = window.navigator.platform;
      let os = null;

      if (['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K', 'Mac'].indexOf(platform) !== -1) {
        os = 'mac';
      } else if (['iPhone', 'iPad', 'iPod'].indexOf(platform) !== -1) {
        os = 'ios';
      } else if (['Win32', 'Win64', 'Windows', 'WinCE'].indexOf(platform) !== -1) {
        os = 'windows';
      } else if (/Android/.test(window.navigator.userAgent)) {
        os = 'android';
      } else if (/Linux/.test(platform)) {
        os = 'linux';
      }

      return os;
    }
  }
}
</script>

<style scoped>
.blurry-text {
  color: transparent;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
}
</style>