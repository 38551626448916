<template>
  <div class="w-full">
    <div class="table w-full">
      <div class="table-row bg-gray-100">
        <div class="table-cell w-1/4 p-4 font-bold">{{ $t('servers.nw.address') }}</div>
<!--        <div class="table-cell p-4 font-bold" v-if="addresses[0].isIpV4">{{ $t('servers.nw.netmask') }}</div>-->
        <div class="table-cell w-1/4 p-4 font-bold">{{ $t('servers.nw.gateway') }}</div>
        <div class="table-cell w-2/4 p-4 font-bold">{{ $t('servers.nw.rdns') }}</div>
      </div>
      <div v-for="(address, k) in addressesRW" :key="k" class="table-row">
        <div class="table-cell p-4 border-b-2 border-gray-200 align-middle">
          <span v-if="!address.custom">{{ address.ip }}{{ address.netmask === 32 ? '' : '/' + address.netmask }}</span>
          <oh-input v-else v-model="address.ip" :placeholder="$t('servers.nw.address-placeholder')"></oh-input>

        </div>
<!--        <div class="table-cell p-4 border-b-2 border-gray-200 align-middle" v-if="addresses[0].isIpV4">-->
<!--          &lt;!&ndash; TODO Make variable &ndash;&gt;-->
<!--          255.255.255.224-->
<!--        </div>-->
        <div class="table-cell p-4 border-b-2 border-gray-200 align-middle">{{ address.gateway }}</div>
        <div class="table-cell p-4 border-b-2 border-gray-200">
          <div class="relative">
            <oh-input additional-class="pr-16" v-model="address.rDNS"></oh-input>
            <div
                @click="updateRDNS(address)"
                :title="$t('servers.nw.apply-changes')"
                class="transition-all duration-300 hover:bg-oh-blue hover:text-white hover:shadow-xl cursor-pointer absolute top-0 right-0 block mr-1 mt-2 px-5 py-2 text-gray-800 border border-gray-300 rounded-lg"
            >
              <i class="fas fa-check"></i></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="expandable" class="mt-5">
      <oh-button type="primary" @click="addAddress">
        <i class="fas fa-plus mr-1"></i> {{ $t('servers.nw.add') }}
      </oh-button>
    </div>
  </div>
</template>

<script>
import {api} from "@/api";
import OhInput from "@/components/OhInput";
import OhButton from "@/components/OhButton";

export default {
  components: {OhButton, OhInput},
  props: {
    product: Object,
    addresses: Array,
    expandable: {default: false}
  },
  data() {
    return {
      addressesRW: this.addresses
    };
  },
  methods: {
    async updateRDNS(address) {
      try {
        await api.put('user/products/' + this.product.id + '/ip/' + address.ip, address);
        this.$emit('change');
      } catch (e) {
        if (e.response.status === 403) {
          alert(this.$t('servers.nw.not-in-subnet'))
        } else {
          alert(this.$t('servers.nw.invalid'));
        }
      }
    },
    addAddress() {
      this.addressesRW.push({
        custom: true,
        isIpV4: false,
        isPrimary: false,
        ip: null,
        netmask: this.addresses[0].netmask,
        gateway: this.addresses[0].gateway,
        rDNS: null
      });
    }
  },
  watch: {
    addresses() {
      this.addressesRW = this.addresses;
    }
  }
}
</script>

<style scoped>

</style>