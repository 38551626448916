<template>
  <card class="w-full">
    <template slot="title">
      <div class="flex">
        {{ $t('storage.product.title', [name || product.product.name, product.settings.initialLoginUser]) }}
      </div>
    </template>
    <div>
      <div class="md:flex mb-3">
        <label class="block p-3 font-bold w-60">{{ $t('storage.product.name') }}</label>
        <div class="flex-1">
          <input type="text" @keyup="updateName"
                 v-model="name"
                 :placeholder="product.product.name"
                 class="border border-gray-300 appearance-none rounded-md w-full p-3 focus:border-oh-blue focus:outline-none active:outline-none active:border-oh-blue focus:shadow-input"
          >
        </div>
      </div>
      <div class="md:flex mb-3">
        <label class="block pl-3 mt-6 font-bold w-60">{{ $t('storage.product.type') }}</label>
        <div class="flex-1">
          <div class="bg-gray-200 font-mono p-2 mt-4">
            {{ product.product.name }}
          </div>
        </div>
      </div>
      <div class="md:flex mb-3">
        <label class="block pl-3 mt-6 font-bold w-60">{{ $t('storage.product.user') }}</label>
        <div class="flex-1">
          <div class="bg-gray-200 font-mono p-2 mt-4">
            {{ product.settings.initialLoginUser }}
          </div>
        </div>
      </div>
      <div class="md:flex mb-3">
        <label class="block pl-3 mt-6 font-bold w-60">{{ $t('storage.product.password') }}</label>
        <div class="flex-1">
          <div class="bg-gray-200 font-mono p-2 mt-4">
              <span v-if="passwordShown">
                {{ product.settings.initialLoginPassword }}
              </span>
              <span v-else class="blurry-text cursor-pointer" @click="passwordShown = true">
                {{ '*'.repeat(product.settings.initialLoginPassword.length) }}
              </span>
              <i class="fas cursor-pointer ml-1" :class="passwordShown ? 'fa-eye' : 'fa-eye-slash'" @click.prevent="passwordShown = !passwordShown"></i>
          </div>
        </div>
      </div>
      <div class="md:flex mb-3">
        <label class="block pl-3 mt-6 font-bold w-60">{{ $t('storage.product.access') }}</label>
        <div class="flex-1">
          <div class="bg-gray-900 text-white font-mono p-2 mt-4">
            smb://{{ product.settings.initialLoginUser }}@{{ product.settings.hostname }}/{{ product.settings.initialLoginUser }}
          </div>
        </div>
      </div>
      <p class="py-2">{{ $t('storage.product.access-text') }}</p>
      <p><a href="https://docs.oakhost.net/network-storage" target="_blank" class="underline">
        {{ $t('storage.product.help') }}<i class="fas fa-external-link-alt ml-2"></i>
      </a></p>
    </div>
  </card>
</template>

<script>
import {api} from "@/api";
import Card from "../Card";
import _ from "lodash";

export default {
  components: {Card},
  props: {
    product: {
      required: true
    }
  },
  data() {
    return {
      name: this.product.settings.name,
      passwordShown: false,
      debounce: _.debounce(() => {
        api.put('user/products/' + this.product.id + '/settings', {
          name: this.name || this.product.product.name
        })
      }, 500),
    };
  },
  methods: {
    updateName() {
      this.product.settings.name = this.name
      this.debounce()
    },
  },
  watch: {
    'product.settings.name'() {
      this.name = this.product.settings.name
    }
  }
}
</script>

<style scoped>
  .blurry-text {
      color: transparent;
      text-shadow: 0 0 10px rgba(0,0,0,0.8);
  }
</style>