<template>
  <main-layout>
    <template slot="title">
      {{ $t('nav.affiliate') }}
    </template>
    <div class="min-h-full">
      <div class="m-auto max-w-7xl">
        <card class="w-full" v-if="loading">
          <template slot="title">
            {{ $t('nav.affiliate') }}
          </template>
          {{ $t('account.loading') }}
        </card>
        <card class="w-full" v-if="!loading">
          <template slot="title">
            {{ $t('affiliate.details-title') }}
          </template>
          <p>{{ $t('affiliate.code-teaser') }}</p>
          <p>{{ $t('affiliate.link-ref') }}</p>
          <code class="bg-gray-900 text-white font-mono p-2 mt-3 mb-4 block">
            ?ref={{ user.account.settings.affiliate_ref }}
          </code>
          <p>{{ $t('affiliate.example') }}</p>
          <code class="bg-gray-900 text-white font-mono p-2 mt-3 block">
            https://www.oakhost.net/mac-mini-hosting?ref={{ user.account.settings.affiliate_ref }}
          </code>
        </card>
        <card class="w-full" v-if="!loading">
          <template slot="title">
            {{ $t('affiliate.overview-title') }}
          </template>
          <p>{{ $t('affiliate.teaser') }}</p>
          <p class="mt-3"><strong>{{ $t('affiliate.unpaid', [formatCurrency(unpaidConfirmed)]) }}</strong></p>
          <p>{{ $t('affiliate.unpaid-info') }}</p>
          <div v-if="!entries.length" class="mt-5">
            <strong>{{ $t('affiliate.empty') }}</strong>
          </div>
          <table class="text-left w-full mt-5" v-else>
            <thead>
            <tr class="border-b border-gray-400">
              <th class="px-3 py-3">{{ $t('affiliate.date') }}</th>
              <th class="px-3 py-3 text-right">{{ $t('affiliate.volume') }}</th>
              <th class="px-3 py-3 text-right">{{ $t('affiliate.share') }}</th>
              <th class="px-3 py-3">{{ $t('affiliate.status_title') }}</th>
              <th class="px-3 py-3">{{ $t('affiliate.paid_out') }}</th>
              <th class="px-3 py-3">{{ $t('affiliate.renewal') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(entry, key) in entries" :key="key"
                class="hover:bg-gray-100 border-b border-gray-200"
                :class="{'text-gray-400 line-through': entry.status === 'refunded', 'text-gray-400': entry.status === 'unconfirmed'}"
            >
              <td class="px-3 py-3">{{ entry.created_at }}</td>
              <td class="px-3 py-3 text-right">{{ entry.volume | formatCurrency }}</td>
              <td class="px-3 py-3 text-right">{{ entry.share_volume | formatCurrency }}</td>
              <td class="px-3 py-3">{{ $t('affiliate.status.' + entry.status) }}</td>
              <td class="px-3 py-3"><i class="fas" :class="entry.paid_out ? 'fa-check' : 'fa-times'"></i></td>
              <td class="px-3 py-3"><i class="fas" :class="entry.is_renewal ? 'fa-check' : 'fa-times'"></i></td>
            </tr>
            </tbody>
          </table>
        </card>
      </div>
    </div>
  </main-layout>
</template>

<script>

import MainLayout from "@/components/Layout/MainLayout";
import Card from "@/components/Card";
import {api} from "@/api";
import {globalData} from "@/globalData";

export default {
  components: {
    Card,
    MainLayout
  },
  data() {
    return {
      loading: true,
      unpaidConfirmed: 0,
      entries: []
    }
  },
  async mounted() {
    globalData.activeRoute = 'affiliate';
    let response = await api.get('affiliate/purchases');
    this.entries = response.data.entries;
    this.unpaidConfirmed = response.data.unpaid_confirmed;
    this.loading = false;
  },
  methods: {
    formatCurrency(value) {
      return window.formatCurrency(value);
    }
  },
  computed: {
    user() {
      return globalData.user;
    }
  }
}
</script>
