<template>
  <main-layout>
    <template slot="title">
      {{ $t('nav.log') }}
    </template>
    <div class="min-h-full">
      <div class="m-auto max-w-7xl">
        <card class="w-full">
          <template slot="title">
            {{ $t('log.headline') }}
          </template>
          <p>{{ $t('log.teaser') }}</p>
          <table class="text-left w-full mt-5">
            <thead>
            <tr class="border-b border-gray-400">
              <th class="px-3 py-3">{{ $t('log.action') }}</th>
              <th class="px-3 py-3 hidden md:table-cell">{{ $t('log.ip') }}</th>
              <th class="px-3 py-3 hidden md:table-cell">{{ $t('log.user') }}</th>
              <th class="px-3 py-3">{{ $t('log.date') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(entry, key) in entries" :key="key"
                class="hover:bg-gray-100 border-b border-gray-200">
              <td class="px-3 py-3">{{ entry.message }}</td>
              <td class="px-3 py-3 hidden md:table-cell">{{ entry.ip }}</td>
              <td class="px-3 py-3 hidden md:table-cell">{{ entry.performed_by }}</td>
              <td class="px-3 py-3">{{ entry.performed_at | formatDateTime }}</td>
            </tr>
            </tbody>
          </table>
        </card>
      </div>
    </div>
  </main-layout>
</template>

<script>

import MainLayout from "@/components/Layout/MainLayout";
import Card from "@/components/Card";
import {api} from "@/api";
import {globalData} from "@/globalData";

export default {
  components: {
    Card,
    MainLayout
  },
  data() {
    return {
      entries: []
    }
  },
  async mounted() {
    let response = await api.get('user/activity_logs');
    this.entries = response.data;
    globalData.activeRoute = 'log';
  }
}
</script>
