import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import '@fortawesome/fontawesome-free/css/all.css';
import i18n from './i18n'
import moment from 'moment';
import VueMatomo from 'vue-matomo';
import vue_chartist from 'vue-chartist';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

Vue.config.productionTip = false

window.formatCurrency = (value) => {
    return (value / 100).toFixed(2) + ' €'
};

Vue.filter('formatCurrency', window.formatCurrency);

Vue.filter('formatDate', (value) => {
    if (value) {
        return moment(String(value)).format('YYYY-MM-DD')
    }
});

Vue.filter('formatDateTime', (value) => {
    if (value) {
        return moment(String(value)).format('YYYY-MM-DD HH:mm')
    }
});

Vue.filter('formatBytes', (bytes, si = false, dp = 2) => {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
});

Vue.use(vue_chartist);
Vue.use(VueMatomo, {
    host: 'https://stats.oakhost.net',
    siteId: 2,
    router: router,
    disableCookies: true
});

if (process.env.VUE_APP_INIT_SENTRY !== 'false') {
    Sentry.init({
        Vue,
        dsn: "https://1dc04efe232e4c9e8780c17e430d6b2e@o1092313.ingest.sentry.io/6110559",
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ["customer.oakhost.test", "customer.oakhost.net", /^\//],
            }),
        ]
    });
}

new Vue({
    router,
    i18n,
    render: h => h(App)
}).$mount('#app')
