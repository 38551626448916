<template>
  <router-link :to="'/servers/' + server.id" class="block md:flex w-full py-4 md:px-4 border-t border-gray-200 hover:bg-gray-100">
    <div class="font-bold mr-2 md:w-3/6 align-middle">{{ server.settings.name }}</div>
    <div class="italic flex-1 md:w-1/6 align-middle">{{ server.settings.ipAddresses[0].ip }}</div>
    <div class="text-sm text-gray-500 md:w-2/6 align-middle text-right">
      {{ server.product.name }}
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    server: {required: true}
  }
}
</script>

<style scoped>

</style>