<template>
  <div>
    <div class="flex text-sm">
      <label :for="id" class="flex-1 text-gray-600 font-bold tracking-tight">{{ label }}</label>
      <span class="text-gray-500">
        <slot name="help"></slot>
      </span>
    </div>
    <textarea
        :id="id"
        :name="id"
        :ref="id"
        :type="type"
        class="w-full outline-none block mt-1 px-5 py-3 text-gray-800 border border-gray-300 rounded-2xl"
        :value="value"
        :readonly="readonly"
        :placeholder="placeholder"
        :class="[
            readonly ? 'bg-gray-200' : 'focus:border-oh-blue focus:shadow-lg',
            additionalClass
        ]"
        @input="input"
    ></textarea>
  </div>
</template>

<script>
export default {
  props: {
    value: {required: true},
    label: String,
    id: String,
    type: {default: 'text'},
    readonly: {default: false},
    placeholder: {default: ''},
    additionalClass: {default: ''}
  },
  methods: {
    input(event) {
      this.$emit('input', event.target.value);
    },
    focus() {
      this.$refs[this.id].focus();
    },
  }
}
</script>

<style scoped>

</style>