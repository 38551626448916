<template>
  <div>
    <div v-if="message"
         class="p-6 mb-5 font-bold" :class="{
            'bg-red-100': messageType === 'error',
            'bg-green-100': messageType === 'success',
            'bg-blue-100': messageType === 'info'
         }">
      {{ message }}
      <div v-if="messageType === 'success'" class="mt-1">
        <a @click="openVNC"
           v-if="product.settings.capabilities.includes('vnc')"
           class="cursor-pointer text-oh-blue hover:underline italic">
          {{ $t('servers.setting.message-success-link') }}
        </a>
        <span v-else class="italic">
          {{ $t('servers.setting.message-success-linux') }}
        </span>
      </div>
    </div>

    <h2 class="text-xl border-b border-gray-200 pb-2 mb-4 font-bold tracking-wider">
      {{ $t('servers.setting.general') }}
    </h2>

    <div class="flex">
      <div class="flex-1">
        <div class="md:flex mb-3">
          <label class="block p-3 font-bold w-60">{{ $t('servers.setting.name') }}</label>
          <div class="flex-1">
            <input type="text" @keyup="updateSettings"
                   v-model="name"
                   :placeholder="product.product.name"
                   class="border border-gray-300 appearance-none rounded-md w-full p-3 focus:border-oh-blue focus:outline-none active:outline-none active:border-oh-blue focus:shadow-input"
            >
          </div>
        </div>
        <div class="md:flex mb-3">
          <label class="block p-3 font-bold w-60">{{ $t('servers.setting.product-name') }}</label>
          <div class="flex-1 p-3">
            {{ product.product.name }}
          </div>
        </div>
        <div class="md:flex mb-3">
          <label class="block p-3 font-bold w-60">{{ $t('servers.setting.primary-ip') }}</label>
          <div class="flex-1 p-3 font-mono">
            {{ product.settings.ipAddresses[0].ip }}
          </div>
        </div>
        <div class="md:flex mb-3">
          <label class="block p-3 font-bold w-60">
            {{ $t('servers.setting.status') }}
            <a href="#" class="inline-block ml-1 text-sm" @mouseenter="showSettingsHelp = true" @mouseleave="showSettingsHelp = false">
              <i class="far fa-question-circle text-lg mr-2 text-gray"></i>
            </a>
            <span class="relative" v-if="showSettingsHelp">
              <span class="absolute block bg-gray-200 px-5 py-2 rounded-lg mt-2 w-500px">
                {{ $t('servers.setting.status-help') }}
                <span v-if="product.type === 'mac'">{{ $t('servers.setting.status-help-mac') }}</span>
              </span>
            </span>
          </label>
          <div class="flex-1 p-3">
            <div v-if="status.status === 'online'" class="font-bold text-green-600">
              <i class="fas fa-circle"></i>
              Online
            </div>
            <div v-if="status.status === 'offline'" class="font-bold text-oh-red">
              <i class="fas fa-circle"></i>
              Offline
            </div>
            <div v-if="!status.status" class="italic">
              Loading...
            </div>
          </div>
        </div>
        <div class="md:flex">
          <label class="block p-3 font-bold w-60">{{ $t('servers.setting.comment') }}</label>
          <div class="flex-1">
            <textarea @keyup="updateSettings" v-model="comment" maxlength="1000"
                   class="border border-gray-300 appearance-none rounded-md w-full h-32 p-3 focus:border-oh-blue focus:outline-none active:outline-none active:border-oh-blue focus:shadow-input"
            ></textarea>
            <span class="text-sm text-gray-500">{{ $t('servers.setting.comment-description') }}</span>
          </div>
        </div>
      </div>
      <div class="pl-10 pr-5 mt-12">
        <img :src="productImage" class="w-48">
      </div>
    </div>

    <div class="mt-10">
      <div class="flex border-b border-gray-200 pb-2 mb-4">
        <h2 class="text-xl font-bold tracking-wider flex-1">{{ $t('servers.setting.actions') }}</h2>
        <a v-if="product.type === 'mac'" :href="$t('help-link') + 'control-your-mac'" target="_blank"><i
            class="far fa-question-circle text-2xl mr-2 text-oh-red"></i></a>
      </div>
      <div class="md:flex">
        <div class="p-3">
          <oh-button @click="startUp()" type="primary" button-class="block md:inline w-full">{{ $t('servers.setting.start') }}</oh-button>
        </div>
        <div class="p-3">
          <oh-button @click="forceOff()" type="danger-outline" button-class="block md:inline w-full">{{ $t('servers.setting.force-off') }}</oh-button>
        </div>
        <div class="p-3">
          <oh-button @click="forceReboot()" type="danger-outline" button-class="block md:inline w-full">{{ $t('servers.setting.force-reboot') }}</oh-button>
        </div>
      </div>
      <div v-if="product.type === 'mac'" class="text-right text-oh-blue hover:underline text-sm italic p-3">
        <a :href="$t('help-link') + 'control-your-mac#booting-into-recovery-mode'" target="_blank">{{ $t('servers.setting.recovery-help') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import OhButton from "@/components/OhButton";
import {api} from "@/api";
import _ from 'lodash';

export default {
  components: {OhButton},
  props: {
    product: {
      required: true
    }
  },
  data() {
    return {
      name: this.product.settings.name,
      comment: this.product.customer_comment,
      loadingAction: false,
      showSettingsHelp: false,
      message: null,
      messageType: 'success',
      debounce: _.debounce(() => {
        api.put('user/products/' + this.product.id + '/settings', {
          name: this.name,
          comment: this.comment
        });
      }, 500),
      pingInterval: null,
      status: {status: null},
      statusErrors: 0
    };
  },
  mounted() {
    this.pingInterval = setInterval(this.getStatus, 2000);
    this.getStatus();
  },
  beforeDestroy() {
    clearInterval(this.pingInterval)
  },
  methods: {
    async getStatus() {
        if (this.statusErrors > 5) {
            // Too many errors, stop trying to avoid spamming the API
            clearInterval(this.pingInterval)
            this.status.status = null
        }

        try {
            this.status = (await api.get('user/products/' + this.product.id + '/status')).data;
            this.statusErrors = 0;
        } catch (e) {
            this.statusErrors++;
            if ((e.response.status || null) === 401) {
              window.location.reload();
            }
        }
    },
    updateSettings() {
      this.product.settings.name = this.name || this.product.product.name;
      this.product.customer_comment = this.comment;
      this.debounce();
    },
    startUp() {
      this.executeAction('power');
    },
    forceOff() {
      if (!confirm(this.$t('servers.setting.confirm-action'))) {
        return;
      }
      this.executeAction('force_off');
    },
    forceReboot() {
      if (!confirm(this.$t('servers.setting.confirm-action'))) {
        return;
      }
      this.executeAction('force_reboot');
    },
    async executeAction(action) {
      if (this.loadingAction) {
        return;
      }

      this.loadingAction = true;
      this.message = this.$t('servers.setting.message-action-loading');
      this.messageType = 'info';

      try {
        await api.post('user/products/' + this.product.id + '/action', {
          action: action
        });
        setTimeout(() => {
          this.loadingAction = false;
          this.messageType = 'success';
          this.message = this.$t('servers.setting.message-action-success');
        }, 5000)
      } catch (e) {
        this.messageType = 'error';
        this.message = this.$t('servers.setting.message-action-error');
        this.loadingAction = false;
      }
    },
    openVNC() {
      window.open('/kvm/' + this.product.id + '?nosidebar=1', 'winname', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,width=1920,height=1080');
    }
  },
  computed: {
    productImage() {
      switch (this.product.type) {
        case 'mac':
          return '/images/mac_mini_2020.png';
        default:
          return '/images/ubuntu.png';
      }
    }
  },
  watch: {
    'product.settings.name'() {
      this.name = this.product.settings.name;
    }
  }
}
</script>

<style scoped>

</style>