<template>
  <div class="h-screen w-full bg-gray-100">
    <div class="absolute pointer-events-none top-0 left-0 right-0 transition-transform duration-500"
         ref="topBarContainer">
      <div
          ref="topBar"
          class="mx-auto w-kvm-top bg-oh-blue text-white text-sm shadow-lg flex pt-2 pb-3 px-1 pointer-events-auto">
        <div class="flex-1 pl-4" v-if="product">
          <span class="font-bold block mt-1">{{ product.settings.name }}</span>
          <span class="text-xs">{{ product.settings.ipAddresses[0].ip }}</span>
        </div>
        <div @click="executeAction('power')"
             class="text-center text-sm cursor-pointer border-l border-white text-oh-yellow w-16 pt-1">
          <i class="fas fa-power-off text-xl"></i>
          <br/>{{ $t('kvm.power') }}
        </div>
        <div @click="executeAction('force_reboot', true)"
             class="text-center text-sm cursor-pointer border-l border-white text-oh-yellow w-16 pt-1">
          <i class="fas fa-redo text-xl"></i>
          <br/>{{ $t('kvm.reset') }}
        </div>
        <div @click="rfb.sendCtrlAltDel()"
             class="text-center text-sm cursor-pointer border-l border-white text-oh-yellow px-2 pt-1">
          <i class="fas fa-backspace text-xl"></i>
          <br/>{{ $t('kvm.ctrl') }}
        </div>
        <a class="block text-center text-sm cursor-pointer border-l border-white text-oh-yellow w-16 pt-1"
           href="https://docs.oakhost.net/access-your-mac/#troubleshooting" target="_blank">
          <i class="fas fa-question text-xl"></i>
          <br/>{{ $t('kvm.help') }}
        </a>
        <div @click="rfb.disconnect()"
             class="text-center text-sm cursor-pointer border-l border-white text-oh-yellow px-2 pt-1">
          <i class="fas fa-times text-xl"></i>
          <br/>{{ $t('kvm.disconnect') }}
        </div>

      </div>
      <div
          @click="showMenu = !showMenu"
          class="mx-auto w-36 h-6 bg-oh-blue text-center text-oh-yellow text-sm cursor-pointer rounded-b-xl shadow-lg pointer-events-auto">
        {{ $t('kvm.actions') }}
        <i class="ml-2 fas" :class="showMenu ? 'fa-angle-double-up' : 'fa-angle-double-down' "></i>
      </div>
    </div>
    <div class="flex h-full">
      <div ref="screen" @contextmenu="() => false" class="flex-1 h-full overflow-hidden"
           :class="{ 'hidden': !isConnected }"></div>
      <div v-if="!isConnected" class="text-center w-full h-full mt-40">
        <div class="mb-10 text-2xl">{{ status }}</div>
        <oh-button v-if="isError" type="default" @click="reload">{{ $t('kvm.reconnect') }}</oh-button>
        <div class="mt-8">
          <a class="hover:text-oh-yellow underline"
             :href="$t('help-link') + 'tutorials/realvnc-viewer/'"
             target="_blank">
            {{ $t('kvm.hint-disconnect') }}
          </a>
        </div>
      </div>
      <div v-if="isExecutingAction"
           class="absolute top-0 right-0 left-0 bottom-0 z-30 bg-black opacity-80 text-white text-center pt-48 text-xl">
        {{ $t('servers.setting.message-action-loading') }}
      </div>
    </div>
    <transition name="feedback-wrapper-translate" mode="out-in">
      <div class="left-0 bottom-0 fixed z-20 w-full" v-if="hintVisible" role="dialog">
        <div class="mx-auto w-full md:w-1/2 max-w-modal bg-oh-blue text-white shadow-lg rounded-t-lg relative pb-3">
          <div class="absolute top-0 right-0 mr-3 mt-3 cursor-pointer" @click="hintVisible = false" role="button" title="Close">
            <i class="text-lg far fa-times-circle"></i>
          </div>
          <div class="p-4">
            <p class="mb-2 font-bold">
              <a class="hover:text-oh-yellow"
                 :href="$t('help-link') + 'tutorials/realvnc-viewer/'"
                 target="_blank">{{ $t('kvm.hint-title') }}</a>
            </p>
            <p><a class="hover:text-oh-yellow underline"
                  :href="$t('help-link') + 'tutorials/realvnc-viewer/'"
                  target="_blank">{{ $t('kvm.hint-body') }}</a></p>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

import {api} from "../api";
import RFB from "@novnc/novnc/core/rfb";
import {initLogging} from "@novnc/novnc/core/util/logging";
import OhButton from "../components/OhButton";
import {session} from "../sessionStore";

export default {
  components: {OhButton},
  data() {
    return {
      showMenu: true,
      status: 'Requesting token. This may take up to 30 seconds...',
      isConnected: false,
      isError: false,
      rfb: null,
      product: null,
      isExecutingAction: false,
      keepaliveInterval: null,
      keepaliveCounter: 0,
      hintVisible: false
    }
  },
  mounted() {
    this.openVNC()
    this.loadProduct()
    setTimeout(() => {
      if (!localStorage.getItem('kvmHintAlreadyShown') || Math.random() <= 0.3) {
        // Show this message after 20-45 minutes or, after confirmation, 30% of the time
        this.hintVisible = true
        localStorage.setItem('kvmHintAlreadyShown', '1')
      }
    }, this.random(20, 45) * 60 * 1000); // random time between 20-45 minutes
  },
  methods: {
    random(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    async loadProduct() {
      this.product = (await api.get('user/products/' + this.$route.params.id)).data;
      setTimeout(() => {
        this.showMenu = false;
      }, 200);
    },
    async openVNC() {
      if (this.isConnected) {
        return;
      }

      try {
        let response = await api.post('user/products/' + this.$route.params.id + '/vnc');
        this.initializeVNC(response.data.url, response.data.t)
      } catch (error) {
        this.status = error.response ? (error.response.data.message || 'An unknown error occurred.') : 'An unknown error occurred.';
        this.isError = true
      }
    },
    initializeVNC(url, t) {
      initLogging('warn')

      this.status = "Connecting...";

      this.rfb = new RFB(this.$refs.screen, url, {credentials: {password: t}});

      this.rfb.addEventListener("connect", () => {
        this.status = 'Activating...';
        this.keepaliveCounter = 0;
        this.isError = false;
        // Send left alt key to activate device
        this.rfb.sendKey(0xffe9, 'AltLeft')
        this.keepaliveInterval = setInterval(this.sendKeepalive, 30000);
        window.addEventListener('beforeunload', this.sendDisconnect);

        // Delay showing the screen to prevent flickering
        setTimeout(this.showScreen, 3000);
      })
      this.rfb.addEventListener("connecting", () => this.status = 'Connecting...')
      this.rfb.addEventListener("disconnect", (e) => {
        this.sendDisconnect();
        this.isConnected = false;
        this.isError = true;
        if (e.detail.clean) {
          this.status = 'Disconnected.';
        } else {
          this.sendDisconnect(true);
          this.status = 'Unexpected disconnect. Please try again in a few seconds, or try a different browser.'
          throw e
        }
      });
      this.rfb.scaleViewport = true
      this.rfb.clipViewport = false
      this.rfb.showDotCursor = true
      this.rfb.qualityLevel = 6
      this.rfb.compressionLevel = 2
    },
    showScreen() {
      this.status = 'Connected';
      this.isConnected = true;
    },
    reload() {
      this.sendDisconnect()
      window.location.reload()
    },
    sendKeepalive() {
      if (this.keepaliveCounter > 60 * 2) {
        // No more keepalives after one hour.
        return;
      }
      this.keepaliveCounter++;
      api.put('user/products/' + this.$route.params.id + '/vnc');
    },
    sendDisconnect(force = false) {
      if (!this.isConnected && !force) {
        return
      }
      window.removeEventListener('beforeunload', this.sendDisconnect);
      if (this.keepaliveInterval) {
        clearInterval(this.keepaliveInterval);
        this.keepaliveInterval = null;
        this.keepaliveCounter = 0;
      }

      const blob = new Blob([JSON.stringify({
        token: session.getToken(),
      })], {type: 'application/json'});
      navigator.sendBeacon(process.env.VUE_APP_API_URL + 'user/products/' + this.$route.params.id + '/vnc/disconnect', blob);
    },
    async executeAction(action, shouldConfirm = false) {
      if (shouldConfirm && !confirm(this.$t('servers.setting.confirm-action'))) {
        return;
      }

      this.isExecutingAction = true;
      try {
        await api.post('user/products/' + this.product.id + '/action', {
          action: action
        });
      } catch (e) {
        // error white executing action
      }
      setTimeout(() => {
        this.isExecutingAction = false;
      }, 10000)
    }
  },
  watch: {
    showMenu() {
      let newPosition = this.showMenu ? 0 : this.$refs.topBar.getBoundingClientRect().height;
      this.$refs.topBarContainer.style.transform = 'translate(0, -' + newPosition + 'px)'
    }
  }
}
</script>
<style>
</style>
