<template>
    <transition name="feedback-wrapper-translate" mode="out-in">
        <div class="left-0 bottom-0 fixed z-20 w-full" v-if="visible" role="dialog">
            <div class="mx-auto w-full md:w-1/2 max-w-modal bg-oh-blue text-white shadow-lg rounded-t-lg relative pb-3">
                <div class="absolute top-0 right-0 mr-3 mt-3 cursor-pointer" @click="dismiss" role="button"
                     title="Close">
                    <i class="text-lg far fa-times-circle"></i>
                </div>
                <transition name="feedback-translate" mode="out-in">
                    <div class="p-4" v-if="question === 1" :key="1">
                        <p class="mb-2 font-bold">{{ $t('feedback.rating-question') }}</p>
                        <star-rating v-model="rating"></star-rating>
                    </div>
                    <div class="p-4" v-if="question === 2" :key="2">
                        <p class="mb-2 font-bold pr-8">{{ $t('feedback.open-question') }}</p>
                        <form class="flex space-x-4" @submit.prevent="submit">
                            <div class="flex-1">
                                <oh-textarea v-model="feedback" ref="feedbackInput" id="feedbackInput"
                                             :placeholder="$t('feedback.placeholder')"></oh-textarea>
                            </div>
                            <oh-button class="block pt-7 mt-1" @click="submit" title="Submit" type="warning"><i
                                    class="fas text-2xl fa-angle-right"></i></oh-button>
                        </form>
                    </div>
                    <div class="p-4" v-if="question === 3" :key="3">
                        <p class="mb-2 font-bold" v-if="rating >= 3">{{ $t('feedback.thanks-ok') }}</p>
                        <p class="mb-2 font-bold" v-else>{{ $t('feedback.thanks-followup') }}</p>
                    </div>
                </transition>
            </div>
        </div>
    </transition>
</template>

<script>
import {globalData} from "@/globalData";
import StarRating from "./Elements/StarRating";
import OhTextarea from "./OhTextarea";
import OhButton from "./OhButton";
import {api} from "../api";
import moment from "moment";

export default {
    components: {OhButton, OhTextarea, StarRating},
    data() {
        return {
            rating: null,
            feedback: null,
            question: 1,
            visible: false
        }
    },
    mounted() {
        setTimeout(() => {
            if (this.$route.query.nosidebar) {
                // don't show feedback if we're in a no-sidebar view
                return;
            }
            let lastFeedback = this.user.settings.last_feedback_at;
            if ((!lastFeedback && this.subscriptionIsUsed) || moment(lastFeedback).add(6, 'months').isBefore()) {
                this.visible = true;
            }
        }, 2000);
    },
    methods: {
        dismiss() {
            this.visible = false;
            api.post('feedback', {rating: this.rating, comment: this.feedback, closed: true});
        },
        submit() {
            this.question++;

            if (this.question === 2 && this.rating >= 3) {
                // window.open('https://www.trustpilot.com/evaluate/oakhost.net', '_blank');
              this.dismiss()
              window.open('https://g.page/r/CT1n7gq-emI8EBM/review', '_blank');
            } else {
                api.post('feedback', {rating: this.rating, comment: this.feedback, closed: false});
            }
        }
    },
    computed: {
        user() {
            return globalData.user;
        },
        subscriptions() {
            return globalData.subscriptions;
        },
        subscriptionIsUsed() {
            for (const k in this.subscriptions) {
                const subscription = this.subscriptions[k];
                if (subscription.status === 'active' && moment(subscription.created_at).add(24, 'hours').isBefore()) {
                    return true;
                }
            }
            return false;
        }
    },
    watch: {
        rating() {
            setTimeout(() => {
                this.submit();
                setTimeout(() => {
                    if (this.$refs.feedbackInput) {
                        this.$refs.feedbackInput.focus();
                    }
                }, 500);
            }, 500);
        },
        question() {
            if (this.question === 3) {
                setTimeout(() => {
                    this.visible = false;
                }, this.rating >= 3 ? 3000 : 5000);
            }
        }
    }
}
</script>

<style>
.feedback-translate-enter-active, .feedback-translate-leave-active {
    transition: all 0.3s;
}

.feedback-translate-enter, .feedback-translate-leave-active {
    opacity: 0;
}

.feedback-translate-enter {
    transform: translateX(40px);
}

.feedback-translate-leave-active {
    transform: translateX(-40px);
}


.feedback-wrapper-translate-enter-active, .feedback-wrapper-translate-leave-active {
    transition: all 0.3s;
}

.feedback-wrapper-translate-enter, .feedback-wrapper-translate-leave-active {
    opacity: 0;
}

.feedback-wrapper-translate-enter, .feedback-wrapper-translate-leave-active {
    transform: translateY(80px);
}
</style>