<template>
  <div>
    <chartist
        class="traffic"
        style="height: 160px;"
        ratio="ct-major-second"
        type="Line"
        :data="chartDataFormatted"
        :options="chartOptions">
    </chartist>

    <div class="flex mb-5 ml-8">
      <div class="mr-5">
        <div class="inline-block w-3 h-3 bg-green-500 rounded-full mr-3"></div>
        <span>{{ $t('servers.stats.inbound') }}: {{ inboundTraffic | formatBytes }}</span>
      </div>
      <div>
        <div class="inline-block w-3 h-3 bg-oh-blue rounded-full mr-3"></div>
        <span>{{ $t('servers.stats.outbound') }}: {{ outboundTraffic | formatBytes }}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    chartData: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      chartOptions: {
        fullWidth: true,
        height: 150,
        chartPadding: {
          right: 40,
        }
      }
    };
  },
  computed: {
    chartDataFormatted() {
      let data = JSON.parse(JSON.stringify(this.chartData));
      data.series[0] = data.series[0].map(d => d / 1024 / 1024 / 1024);
      data.series[1] = data.series[1].map(d => d / 1024 / 1024 / 1024);

      return data;
    },
    inboundTraffic() {
      return this.chartData.series[0].reduce((t, v) => t + v, 0);
    },
    outboundTraffic() {
      return this.chartData.series[1].reduce((t, v) => t + v, 0);
    },
  }
}
</script>

<style>
.traffic .ct-series-a .ct-line {
  stroke: rgb(16, 185, 129);
}

.traffic .ct-series-a .ct-point {
  stroke: rgb(16, 185, 129);
}

.traffic .ct-series-b .ct-line {
  stroke: #233b88;
}

.traffic .ct-series-b .ct-point {
  stroke: #233b88;
}
</style>