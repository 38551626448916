<template>
  <card class="w-full">
    <template slot="title">
      <span class="block md:flex">
        <span class="block flex-1 mb-4 md:mb-0">{{ $t('monitoring.alerts') }}</span>
        <span class="block">
          <oh-button type="primary" class="text-sm" @click="openAlert">
            <i class="fas fa-plus mr-1"></i> {{ $t('monitoring.add-alert') }}
          </oh-button>
        </span>
      </span>
    </template>
    <p>{{ $t('monitoring.alerts-teaser') }}</p>

    <table class="mt-5 border-t border-gray-200 w-full" v-if="alerts.length">
      <tr v-for="entry in alerts" :key="entry.id"
          class="hover:bg-gray-100 border-b border-gray-200 cursor-pointer" @click="openAlert(entry)">
        <td class="font-bold p-5">
          {{ entry.name }}
        </td>
        <td class="text-gray-400 text-sm p-5">
          <span v-if="entry.type === 'email'">
            {{ entry.settings.email }}
          </span>
        </td>
        <td class="p-5 text-right">
          <div
              class="inline-block rounded shadow-inner w-20 px-2 py-1 text-xs text-white text-center font-bold bg-gray-500">
            {{ $t('monitoring.type.' + entry.type) }}
          </div>
        </td>
      </tr>
    </table>
    <div v-if="!alerts.length && !loading" class="text-center mt-5 p-10 w-full bg-blue-100 font-bold">
      {{ $t('monitoring.no-alerts') }}
    </div>
    <div v-if="loading" class="italic mt-10">
      {{ $t('main.loading') }}
    </div>

    <add-edit-alert-modal
        :show="showModal" :alert="selectedAlert"
        @close="showModal = false" @created="$emit('refreshAlerts')" @deleted="$emit('refreshAlerts')"
    ></add-edit-alert-modal>
  </card>
</template>

<script>
import Card from "@/components/Card";
import OhButton from "@/components/OhButton";
import AddEditAlertModal from "@/components/Monitoring/AddEditAlertModal";

const ALERT_TEMPLATE = {
  id: null,
  name: null,
  type: 'email',
  settings: {email: null}
};

export default {
  components: {
    AddEditAlertModal,
    OhButton,
    Card
  },
  props: {
    alerts: {required: true},
    loading: {required: true},
  },
  data() {
    return {
      showModal: false,
      selectedAlert: ALERT_TEMPLATE
    };
  },
  methods: {
    openAlert(alert = null) {
      this.selectedAlert = alert || ALERT_TEMPLATE;
      this.showModal = true;
    }
  }
}
</script>

<style scoped>

</style>