<template>
  <tr class="border-b-2 border-gray-200">
    <td class="py-4 pl-4 pr-4">
      <oh-select v-model="selectedTypeId">
        <option v-if="empty" :value="0">{{ $t('servers.fw.rule.types.new') }}</option>
        <option v-for="type in filteredTypes" :key="type.id" :value="type.id">{{ type.name }}</option>
      </oh-select>
    </td>
    <td colspan="6" v-if="empty">
      <!-- Placeholder-->
    </td>
    <td class="pr-4" v-if="!empty">
      <span v-if="!selectedType.custom">{{ rule.protocol }}</span>
      <oh-select v-else v-model="rule.protocol">
        <option value="tcp">TCP</option>
        <option value="udp">UDP</option>
      </oh-select>
    </td>
    <td class="pr-4" v-if="!empty">
      <span v-if="!selectedType.custom">{{ rule.ports }}</span>
      <oh-input v-else v-model="rule.ports" :placeholder="$t('servers.fw.rule.ports-all')"></oh-input>
    </td>
    <td class="pr-4" v-if="!empty">
      <oh-input v-model="rule.ipv4_addresses" :placeholder="$t('servers.fw.rule.ipv4-all')"></oh-input>
    </td>
    <td class="pr-4" v-if="!empty">
      <oh-input v-model="rule.ipv6_addresses" :placeholder="$t('servers.fw.rule.ipv6-all')"></oh-input>
    </td>
    <td class="pr-4" v-if="!empty">
      <oh-input v-model="rule.comment"></oh-input>
    </td>
    <td class="pr-4 pt-2" v-if="!empty">
      <i class="fas fa-trash-alt text-red-900 text-2xl cursor-pointer" @click="remove"></i>
    </td>
  </tr>
</template>

<script>
import OhSelect from "@/components/OhSelect";
import OhInput from "@/components/OhInput";

export default {
  components: {OhInput, OhSelect},
  props: {
    ruleset: {
      required: true
    },
    rule: {
      required: true
    },
    empty: {default: false}
  },
  data() {
    return {
      selectedTypeId: null,
      types: [
        {id: 1, name: 'SSH', protocol: 'tcp', ports: '22'},
        {id: 2, name: this.$t('servers.fw.rule.types.screen-sharing'), protocol: 'tcp', ports: '88,5900'},
        {id: 3, name: 'HTTP', protocol: 'tcp', ports: '80'},
        {id: 4, name: 'HTTPS', protocol: 'tcp', ports: '443'},
        {id: 5, name: 'ICMP (ping)', protocol: 'icmp', ports: null},
        {id: 6, name: this.$t('servers.fw.rule.types.all-tcp'), protocol: 'tcp', ports: null},
        {id: 7, name: this.$t('servers.fw.rule.types.all-udp'), protocol: 'udp', ports: null},
        {id: 8, name: 'SMB (Network Storage)', protocol: 'tcp', ports: '445', only: 'outbound'},
        {id: 9, name: this.$t('servers.fw.rule.types.custom'), custom: true},
      ]
    }
  },
  mounted() {
    if (this.empty) {
      this.selectedTypeId = 0;
    } else {
      // Set correct type (default to last, because it's the custom one)
      let types = this.types.filter(t => t.protocol === this.rule.protocol && t.ports === this.rule.ports);
      this.selectedTypeId = types.length > 0 ? types[0].id : this.types[this.types.length - 1].id;
    }
  },
  methods: {
    remove() {
      this.$emit('remove')
    }
  },
  watch: {
    selectedTypeId() {
      if (this.selectedType.ports !== undefined) {
        // do not override custom
        this.rule.ports = this.selectedType.ports;
      }

      if (this.selectedType.ports !== undefined) {
        // do not override custom
        this.rule.protocol = this.selectedType.protocol;
      } else if (this.rule.protocol !== 'tcp' && this.rule.protocol !== 'udp') {
        // default to tcp
        this.rule.protocol = 'tcp';
      }

      if (this.empty && this.selectedTypeId) {
        this.$emit('created', {protocol: this.rule.protocol, ports: this.rule.ports});
        this.selectedTypeId = 0;
      }
    }
  },
  computed: {
    filteredTypes() {
      return this.types.filter(t => t.only ? t.only === this.ruleset : true)
    },
    selectedType() {
      return this.selectedTypeId ? this.types.filter(t => t.id == this.selectedTypeId)[0] : {};
    }
  }
}
</script>

<style scoped>

</style>