<template>
  <card class="w-full">
    <template slot="title">
      {{ $t('account.2fa.title') }}
    </template>

    <div v-if="message"
         class="p-6 mb-5 font-bold" :class="type === 'error' ? 'bg-red-100' : 'bg-green-100'"
    >
      {{ message }}
    </div>
    <p class="mb-5">{{ $t('account.2fa.teaser') }}</p>

    <form @submit="enableTFA" v-if="!tokenData.enabled">
      <div class="w-full mb-4">
        <h3 class="text-lg font-bold">{{ $t('account.2fa.enable-title') }}</h3>
      </div>
      <div class="w-full mb-4">
        <p v-html="$t('account.2fa.step1')"></p>
        <div v-html="tokenData.qr_code"></div>
      </div>

      <div class="w-full mb-4">
        <p class="mb-2">{{ $t('account.2fa.step2') }}</p>
        <oh-input :label="$t('account.current-password')" v-model="currentPassword" id="currentPassword2" type="password"></oh-input>
      </div>

      <div class="w-full mb-4">
        <p class="mb-2">{{ $t('account.2fa.step3') }}</p>
        <oh-input :label="$t('account.2fa.code')" v-model="code" id="code" type="text"></oh-input>
      </div>

      <div class="mb-3">
        <p class="mb-4">{{ $t('account.2fa.step4') }}</p>
        <oh-button type="primary" @click="enableTFA">{{ loading ? $t('account.loading') : $t('account.2fa.activate') }}</oh-button>
      </div>
    </form>
    <form @submit="disableTFA" v-if="tokenData.enabled">
      <div class="w-full mb-4">
        <h3 class="text-lg font-bold">{{ $t('account.2fa.disable-title') }}</h3>
      </div>
      <div class="w-full mb-4">
        <p class="font-bold mb-4">{{ $t('account.2fa.enabled') }}</p>
        <p>{{ $t('account.2fa.disable-teaser') }}</p>
      </div>

      <div class="w-full mb-4">
        <oh-input :label="$t('account.current-password')" v-model="currentPassword" id="currentPassword3" type="password"></oh-input>
      </div>

      <div class="w-full mb-8">
        <oh-input :label="$t('account.2fa.code')" v-model="code" id="code2" type="text"></oh-input>
      </div>

      <div class="mb-3">
        <oh-button type="primary" @click="disableTFA">{{ loading ? $t('account.loading') : $t('account.2fa.deactivate') }}</oh-button>
      </div>
    </form>
  </card>
</template>

<script>
import OhButton from "@/components/OhButton";
import OhInput from "@/components/OhInput";
import Card from "@/components/Card";
import {api} from "@/api";
import {globalData} from "@/globalData";

export default {
  components: {
    OhButton,
    OhInput,
    Card
  },
  data() {
    return {
      message: null,
      type: 'success',
      loading: false,
      currentPassword: null,
      code: null,
      tokenData: {enabled: false, qr_code: null, secret: null, token: null}
    }
  },
  mounted() {
    this.loadTokenInformation()
  },
  methods: {
    async loadTokenInformation() {
      let response = await api.get('user/two_factor_auth')
      if (response.data.enabled) {
        this.tokenData.enabled = true
      } else {
        this.tokenData = response.data
      }
    },
    async enableTFA() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.message = null;
      try {
        await api.post('user/two_factor_auth', {
          'current_password': this.currentPassword,
          'token': this.tokenData.token,
          'code': this.code
        });

        this.loading = false;
        this.type = 'success';
        this.message = this.$t('account.2fa.success');
        this.tokenData.enabled = true
      } catch (error) {
        this.loading = false;
        this.type = 'error';
        if (error.response.data && error.response.data.errors) {
          let errors = error.response.data.errors;
          this.message = errors[Object.keys(errors)[0]][0];
        } else {
          this.message = 'Unknown error. Please try again or contact support.';
        }
      }
      this.currentPassword = null;
      this.code = null;
    },
    async disableTFA() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.message = null;
      try {
        await api.delete('user/two_factor_auth', {data: {
          'current_password': this.currentPassword,
          'code': this.code
        }});

        this.loading = false;
        this.type = 'success';
        this.message = this.$t('account.2fa.success-disable');
        this.loadTokenInformation()
      } catch (error) {
        this.loading = false;
        this.type = 'error';
        if (error.response.data && error.response.data.errors) {
          let errors = error.response.data.errors;
          this.message = errors[Object.keys(errors)[0]][0];
        } else {
          this.message = 'Unknown error. Please try again or contact support.';
        }
      }
      this.currentPassword = null;
      this.code = null;
    }
  },
  computed: {
    user() {
      return globalData.user;
    }
  }
}
</script>

<style scoped>

</style>