<template>
  <card class="w-full">
    <template slot="title">
      {{ $t('account.information') }}
    </template>

    <div v-if="message"
         class="p-6 mb-5 font-bold" :class="type === 'error' ? 'bg-red-100' : 'bg-green-100'"
    >
      {{ message }}
    </div>

    <form @submit="updateInformation">
      <div class="lg:flex w-full lg:space-x-6 space-y-4 lg:space-y-0 mb-4">
        <oh-input :label="$t('account.first_name')" :readonly="true" v-model="user.first_name" id="first_name"
                  class="flex-1"></oh-input>
        <oh-input :label="$t('account.last_name')" :readonly="true" v-model="user.last_name" id="last_name"
                  class="flex-1"></oh-input>
      </div>
      <div class="w-full mb-4" v-if="user.company_name">
        <oh-input :label="$t('account.company_name')" :readonly="true" v-model="user.company_name"
                  id="company_name"></oh-input>
      </div>
      <div class="w-full mb-4" v-if="user.vat_id">
        <oh-input :label="$t('account.vat_id')" :readonly="true" v-model="user.vat_id" id="vat_id"></oh-input>
      </div>

      <div v-if="user.primary_address">
        <div class="w-full mb-4">
          <oh-input :label="$t('account.street')" v-model="user.primary_address.street" id="street"></oh-input>
        </div>
        <div class="lg:flex w-full lg:space-x-6 space-y-4 lg:space-y-0 mb-4">
          <oh-input :label="$t('account.postal_code')" v-model="user.primary_address.postal_code" id="postal_code"
                    class="lg:w-80"></oh-input>
          <oh-input :label="$t('account.city')" v-model="user.primary_address.city" id="city"
                    class="flex-1"></oh-input>
        </div>
        <div class="w-full mb-4">
          <oh-input :label="$t('account.country')" :readonly="true" v-model="user.primary_address.country_name"
                    id="country"></oh-input>
        </div>
        <div class="w-full mb-4">
          <oh-input :label="$t('account.phone')" v-model="user.primary_address.phone" id="phone"></oh-input>
        </div>

        <div class="mt-8">
          <oh-button type="primary" @click="updateInformation">{{ loading ? $t('account.loading') : $t('account.update') }}</oh-button>
        </div>

      </div>

      <div class="text-sm text-gray-600 mt-8">{{ $t('account.name-change') }}</div>
    </form>
  </card>
</template>

<script>
import OhButton from "@/components/OhButton";
import OhInput from "@/components/OhInput";
import Card from "@/components/Card";
import {api} from "@/api";
import {globalData} from "@/globalData";

export default {
  components: {
    OhButton,
    OhInput,
    Card
  },
  data() {
    return {
      message: null,
      type: 'success',
      loading: false
    }
  },
  methods: {
    async updateInformation() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.message = null;
      try {
        let response = await api.put('user/primary_address', this.user.primary_address);

        this.loading = false;
        globalData.user = response.data;
        this.type = 'success';
        this.message = this.$t('account.success');
      } catch (error) {
        this.loading = false;
        this.type = 'error';
        if (error.response.data && error.response.data.errors) {
          let errors = error.response.data.errors;
          this.message = errors[Object.keys(errors)[0]][0];
        } else {
          this.message = 'Unknown error. Please try again or contact support.';
        }
      }
    }
  },
  computed: {
    user() {
      return globalData.user;
    }
  }
}
</script>

<style scoped>

</style>