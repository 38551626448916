<template>
  <modal :show="show" @close="close" v-if="dirtyAlert">
    <template slot="title">{{ isNew ? $t('monitoring.add-alert') : $t('monitoring.edit-alert') }}</template>
    <form @submit="save">

      <div v-if="message" class="p-6 mb-5 font-bold bg-red-100">
        {{ message }}
      </div>

      <div class="mb-5">
        <oh-input :label="$t('monitoring.alert-edit.name')"
                  :placeholder="$t('monitoring.alert-edit.name-placeholder')" v-model="dirtyAlert.name"></oh-input>
      </div>

      <div class="mb-5">
        <oh-input :label="$t('monitoring.alert-edit.email')"  :placeholder="$t('monitoring.alert-edit.email-placeholder')"
                  v-model="dirtyAlert.settings.email" type="email"></oh-input>
      </div>

      <div class="mt-10 mb-2 flex">
        <div class="flex-1">
          <oh-button type="primary" @click="save">{{ $t('monitoring.alert-edit.save') }}</oh-button>
        </div>
        <div>
          <oh-button v-if="!isNew" @click="confirmDelete" type="danger">
            {{ $t('monitoring.alert-edit.delete') }}
          </oh-button>

          <oh-button v-else @click="close">{{ $t('monitoring.alert-edit.cancel') }}</oh-button>
        </div>
      </div>
      <input type="submit" class="hidden" @click="save">
    </form>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import OhInput from "@/components/OhInput";
import OhButton from "@/components/OhButton";
import {api} from "@/api";

export default {
  props: {
    show: {required: true},
    alert: {required: true},
  },
  data() {
    return {
      dirtyAlert: null,
      message: null,
    }
  },
  mounted() {
    this.reset();
  },
  methods: {
    async save() {
      this.message = null;

      try {
        let response;
        if (this.isNew) {
          response = await api.post('monitoring/alert', this.dirtyAlert);
        } else {
          response = await api.put('monitoring/alert/' + this.alert.id, this.dirtyAlert);
        }
        this.$emit('created', response.data);
        this.close();
      } catch (e) {
        if (e.response.data.errors) {
          let errors = e.response.data.errors;
          this.message = errors[Object.keys(errors)[0]][0];
        } else {
          this.message = 'Server Error. Please try again later.';
        }
      }
    },
    async confirmDelete() {
      if (!confirm(this.$t('monitoring.alert-edit.delete-confirm'))) {
        return;
      }

      await api.delete('monitoring/alert/' + this.alert.id);
      this.$emit('deleted');
      this.close();
    },
    close() {
      this.$emit('close');
      this.reset();
    },
    reset() {
      this.dirtyAlert = JSON.parse(JSON.stringify(this.alert));
    },
  },
  computed: {
    isNew() {
      return !this.alert.id;
    }
  },
  watch: {
    alert() {
      this.reset();
    },
  },
  components: {
    OhButton,
    OhInput,
    Modal,
  }
}
</script>

<style scoped>

</style>