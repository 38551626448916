<template>
  <div>
    <div v-if="message"
         class="p-6 mb-5 font-bold" :class="{
              'bg-red-100': messageType === 'error',
              'bg-green-100': messageType === 'success',
              'bg-blue-100': messageType === 'info'
           }">
      {{ message }}
    </div>

    <div class="flex border-b border-gray-200 pb-2 mb-4">
      <h2 class="text-xl font-bold tracking-wider flex-1">{{ $t('servers.fw.headline') }}</h2>
      <a :href="$t('help-link') + 'firewall-usage'" target="_blank">
        <i class="far fa-question-circle text-2xl mr-2 text-oh-red"></i>
      </a>
    </div>
    <div class="mb-10">
      <p>{{ $t('servers.fw.description') }}</p>
      <div class="md:flex mt-5">
        <div>
          <oh-toggle v-model="firewallActive" :readonly="loading" @click="firewallStateChangeRequest" :label="$t('servers.fw.toggle')"></oh-toggle>
        </div>
        <div class="ml-2 text-xl font-bold flex-1">
          <span v-if="firewallActive && !loading" class="text-green-700">{{ $t('servers.fw.active') }}</span>
          <span v-if="!firewallActive && !loading" class="text-red-900">{{ $t('servers.fw.inactive') }}</span>
          <span v-if="loading" class="text-gray-700">{{ $t('servers.fw.loading-status') }}</span>
        </div>
        <div class="mt-8 md:mt-0">
          <oh-button @click="createDefaultRules" button-class="block md:inline w-full">{{ $t('servers.fw.restore-default') }}</oh-button>
        </div>
      </div>
    </div>

    <h2 class="text-xl border-b border-gray-200 pb-2 mb-4 font-bold tracking-wider">{{ $t('servers.fw.inbound') }}</h2>
    <div class="mb-10">
      <p class="text-gray-500 text-sm">{{ $t('servers.fw.inbound-description') }}</p>
      <div class="mt-5 overflow-auto">
        <table class="w-full rule-table">
          <thead>
          <tr class="text-left bg-gray-100">
            <th class="p-4 w-2/12">{{ $t('servers.fw.rule.type') }}</th>
            <th class="w-1/12">{{ $t('servers.fw.rule.protocol') }}</th>
            <th class="w-1/12">{{ $t('servers.fw.rule.port') }}</th>
            <th class="w-3/12">{{ $t('servers.fw.rule.ipv4-source') }}</th>
            <th class="w-3/12">{{ $t('servers.fw.rule.ipv6-source') }}</th>
            <th class="w-2/12" colspan="2">{{ $t('servers.fw.rule.comment') }}</th>
          </tr>
          </thead>
          <tbody>
          <rule ruleset="inbound" v-for="rule in inboundRules" :key="rule.id" :rule="rule" @remove="removeRule(rule)"></rule>
          <tr v-if="!inboundRules.length && !loading && firewallActive">
            <td colspan="7" class="p-4 text-red-900 font-bold">
              {{ $t('servers.fw.all-traffic-notice') }}
            </td>
          </tr>
          <rule ruleset="inbound" :empty="true" :rule="{}" @created="createRule($event, 'inbound')"></rule>
          </tbody>
        </table>
      </div>
    </div>

    <h2 class="text-xl border-b border-gray-200 pb-2 mb-4 font-bold tracking-wider">{{ $t('servers.fw.outbound') }}</h2>
    <div class="mb-3">
      <p class="text-gray-500 text-sm">{{ $t('servers.fw.outbound-description') }}</p>
      <div class="mt-5 overflow-auto">
        <table class="w-full rule-table">
          <thead>
          <tr class="text-left bg-gray-100">
            <th class="p-4 w-2/12">{{ $t('servers.fw.rule.type') }}</th>
            <th class="w-1/12">{{ $t('servers.fw.rule.protocol') }}</th>
            <th class="w-1/12">{{ $t('servers.fw.rule.port') }}</th>
            <th class="w-3/12">{{ $t('servers.fw.rule.ipv4-destination') }}</th>
            <th class="w-3/12">{{ $t('servers.fw.rule.ipv6-destination') }}</th>
            <th class="w-2/12" colspan="2">{{ $t('servers.fw.rule.comment') }}</th>
          </tr>
          </thead>
          <tbody>
          <rule ruleset="outbound" v-for="rule in outboundRules" :key="rule.id" :rule="rule" @remove="removeRule(rule)"></rule>
          <tr v-if="!outboundRules.length && !loading && firewallActive">
            <td colspan="7" class="p-4 text-red-900 font-bold">
              {{ $t('servers.fw.all-traffic-notice') }}
            </td>
          </tr>
          <rule ruleset="outbound" :empty="true" :rule="{}" @created="createRule($event, 'outbound')"></rule>
          </tbody>
        </table>
      </div>
    </div>
    <oh-button @click="apply()" :type="loading ? 'default' : 'primary'" class="mt-8 block text-center">
      {{ loading ? $t('servers.fw.loading') : $t('servers.fw.apply') }}
      <span v-if="!loading && !firewallActive">{{ $t('servers.fw.and-activate') }}</span>
    </oh-button>
  </div>
</template>

<script>

import Rule from "@/components/Servers/Firewall/Rule";
import {api} from "@/api";
import OhButton from "@/components/OhButton";
import OhToggle from "@/components/OhToggle";

export default {
  components: {OhToggle, OhButton, Rule},
  props: {
    server: {
      required: true
    },
  },
  data() {
    return {
      message: null,
      messageType: null,
      firewallActive: false,
      defaultMessage: false,
      loading: true,
      rules: [],
      liveRules: []
    };
  },
  async mounted() {
    let response = await api.get('user/products/' + this.server.id + '/firewall/rules');
    this.rules = response.data.rules;
    this.firewallActive = response.data.active;
    this.loading = false;

    window.addEventListener('beforeunload', this.notifyOnDirty);
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.notifyOnDirty);
  },
  beforeRouteLeave(to, from, next) {
    // TODO: Doesn't work. Also, tab switches probably won't count
    const message = this.notifyOnDirty();
    if (message && !window.confirm(message)) {
      next(false);
    } else {
      next();
    }
  },
  methods: {
    createRule(event, type) {
      this.rules.push({
        type: type,
        comment: null,
        id: null,
        ipv4_addresses: null,
        ipv6_addresses: null,
        position: this.rules.length ? this.rules[this.rules.length - 1].position + 1 : 1,
        ...event
      });
    },
    removeRule(rule) {
      this.rules.splice(this.rules.indexOf(rule), 1);
    },
    firewallStateChangeRequest(newState) {
      if (!newState) {
        // disable
        this.disable();
      } else {
        // enable
        if (this.rules.length === 0) {
          this.createDefaultRules();
        }
        this.apply();
      }
    },
    createDefaultRules() {
      this.rules = [
        {comment: 'Default', ports: '22', position: 1, protocol: 'tcp', type: 'inbound'},
        {comment: 'Default', ports: '88,5900', position: 2, protocol: 'tcp', type: 'inbound'},
        {comment: 'Default', ports: null, position: 3, protocol: 'icmp', type: 'outbound'},
        {comment: 'Default', ports: null, position: 4, protocol: 'tcp', type: 'outbound'},
        {comment: 'Default', ports: null, position: 5, protocol: 'udp', type: 'outbound'}
      ];
      this.defaultMessage = true;
    },
    async disable() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.message = null;
      try {
        let response = await api.delete('user/products/' + this.server.id + '/firewall/rules')
        this.messageType = 'success';
        this.message = this.$t('servers.fw.success-disable');
        this.rules = response.data.rules;
        this.liveRules = this.rules;
        this.firewallActive = response.data.active;
      } catch (error) {
        this.catchError(error);
      }
      this.loading = false;
    },
    async apply() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      this.message = null;
      try {
        let response = await api.post('user/products/' + this.server.id + '/firewall/rules', {rules: this.rules})
        this.messageType = 'success';
        if (this.defaultMessage) {
          this.message = this.$t('servers.fw.success-initial');
          this.defaultMessage = false;
        } else {
          this.message = this.$t('servers.fw.success');
        }
        this.rules = response.data.rules;
        this.liveRules = this.rules;
        this.firewallActive = response.data.active;
      } catch (error) {
        this.catchError(error);
      }
      this.loading = false;
    },
    catchError(error) {
      this.messageType = 'error';
      if (error.response.data && error.response.data.errors) {
        let errors = error.response.data.errors;
        this.message = errors[Object.keys(errors)[0]][0];
      } else {
        this.message = 'Unknown error. Please try again later or contact support.';
      }
    },
    notifyOnDirty(e) {
      if (JSON.stringify(this.liveRules) === JSON.stringify(this.rules)) {
        // Cool! All clean
        return;
      }

      const confirmationMessage = this.$t('servers.fw.quit-changes');

      (e || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    }
  },
  computed: {
    inboundRules() {
      return this.rules.filter(rule => rule.type === 'inbound');
    },
    outboundRules() {
      return this.rules.filter(rule => rule.type === 'outbound');
    },
  }
}
</script>

<style scoped>
  .rule-table {
    min-width: 800px;
  }
</style>