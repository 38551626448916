<template>
  <div v-if="user && user.notice" class="w-full bg-yellow-100">
      <div class="min-h-20 p-4 flex items-center m-auto max-w-7xl md:px-6">
          <div class="flex-1 pr-4">
            <span class="user-notice" v-html="user.notice"></span>
          </div>
          <div>
            <div class="cursor-pointer md:pr-4" @click="dismiss" role="button" title="Dismiss">
              <i class="text-2xl far fa-times-circle"></i>
            </div>
          </div>
    </div>
  </div>
</template>

<script>
import {globalData} from "../globalData";
import {api} from "../api";

export default {
  computed: {
    user() {
      return globalData.user;
    },
  },
  methods: {
    dismiss() {
      api.delete('user/notices');
      globalData.user.notice = null;
    }
  }
}
</script>

<style>
  .user-notice a {
    text-decoration: underline;
  }
</style>