<template>
  <div class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
    <input type="checkbox" name="toggle"
           :aria-label="label"
           :id="id" v-model="realVal" :value="true" ref="checkbox" :disabled="readonly" @click="click"
           class="focus:outline-none toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer pointer-events-none"/>
    <label :for="id" class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300" :class="readonly ? 'cursor-not-allowed' : 'cursor-pointer'" @click="click"></label>
  </div>
</template>

<script>
export default {
  props: {
    value: Boolean,
    id: String,
    readonly: {default: false},
    label: String
  },
  data() {
    return {
      realVal: this.value
    }
  },
  methods: {
    click() {
      if (this.readonly) {
        return;
      }
      this.$emit('click', !this.realVal)
    }
  },
  watch: {
    realVal() {
      this.$emit('input', this.realVal);
    },
    value() {
      if (this.value !== this.realVal) {
        this.realVal = this.value;
      }
    }
  }
}
</script>

<style scoped>
.toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #68D391;
}

.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400;
  background-color: #68D391;
}
</style>