<template>
  <div>
    <div class="flex z-0 relative" role="tablist">
      <div class="text-gray-700 mr-1 pl-5 pr-4 md:px-6 py-2 cursor-pointer border-t-4 shadow-md first:rounded-tl-xl last:rounded-tr-xl"
           role="tab"
           v-for="(tab, k) in tabs" :key="k"
           :class="selected === k ? 'bg-white border-oh-yellow': 'bg-gray-300 border-gray-300'"
           :aria-selected="selected === k"
           :title="tab.name"
           @click="select(k)"
      >
        <i :class="[tab.icon, selected === k ? 'text-oh-yellow' : '']" class="mr-2"></i>
        <span class="hidden md:inline-block">{{ tab.name }}</span>
      </div>
    </div>
    <div class="shadow-md rounded-xl rounded-tl-none bg-white text-gray-700 mb-10 z-10 relative">
      <div class="leading-6 p-6">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      required: true
    },
    initialSelection: {
      required: true
    }
  },
  data() {
    return {
      selected: this.initialSelection
    }
  },
  mounted() {
    let hash = window.location.hash.substring(1);
    if (this.tabs[hash] !== undefined) {
      this.select(hash);
    }
  },
  methods: {
    select(key) {
      this.selected = key;
      window.location.hash = '#' + key;
      this.$emit('selected', key);
    }
  }
}
</script>

<style scoped>

</style>