<template>
  <div>
    <div class="flex border-b border-gray-200 pb-2 mb-4">
      <h2 class="text-xl font-bold tracking-wider flex-1">{{ $t('servers.nw.general-title') }}</h2>
      <a :href="$t('help-link') + 'configuration-network'" target="_blank">
        <i class="far fa-question-circle text-2xl mr-2 text-oh-red"></i>
      </a>
    </div>

    <div class="p-3 md:flex mb-10 leading-8">
<!--      <div class="md:flex mr-14">-->
<!--        <div class="font-bold pr-4">{{ $t('servers.nw.dns') }}:</div>-->
<!--        <div class="flex-1 font-mono">116.202.140.162<br/>2a01:4f8:d1:b100::2</div>-->
<!--      </div>-->
      <div class="md:flex">
        <div class="font-bold pr-4">{{ $t('servers.nw.mac') }}:</div>
        <div class="flex-1 font-mono">{{ product.settings.macAddress }}</div>
      </div>
    </div>

    <h2 class="text-xl border-b border-gray-200 pb-2 mb-4 font-bold tracking-wider">{{ $t('servers.nw.ipv4-title') }}</h2>
    <div class="flex mb-10 overflow-auto">
      <ip-table :product="product" @change="updateList"  :addresses="ipv4Addresses" v-if="ipv4Addresses.length"></ip-table>
    </div>

    <h2 class="text-xl border-b border-gray-200 pb-2 mb-4 font-bold tracking-wider">{{ $t('servers.nw.ipv6-title') }}</h2>
    <div class="flex pb-3 overflow-auto">
      <ip-table :product="product" @change="updateList" :addresses="ipv6Addresses" :expandable="true" v-if="ipv4Addresses.length"></ip-table>
    </div>
  </div>
</template>

<script>
import IpTable from "@/components/Servers/Network/IpTable";
import {api} from "@/api";

export default {
  components: {IpTable},
  props: {
    product: {
      required: true
    }
  },
  data() {
    return {
      ipAddresses: []
    }
  },
  mounted() {
    this.updateList();
  },
  methods: {
    async updateList() {
      let response = await api.get('user/products/' + this.product.id + '/ip');
      this.ipAddresses = response.data;
    }
  },
  computed: {
    ipv4Addresses() {
      return this.ipAddresses.filter(ip => ip.isIpV4);
    },
    ipv6Addresses() {
      return this.ipAddresses.filter(ip => !ip.isIpV4);
    }
  }
}
</script>

<style scoped>

</style>