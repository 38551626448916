<template>
  <main-layout>
    <template slot="title">
      {{ $t('nav.dashboard') }}
    </template>
    <div class="min-h-full">
      <div class="m-auto max-w-7xl">

        <div v-if="!user.email_verified_at || user.status !== 'active'" class="p-6 mb-5 font-bold"
             :class="!user.email_verified_at || user.status !== 'new' ? 'bg-red-100' : 'bg-yellow-200'">
          <!-- TODO: Add re-send link -->
          <div v-if="!user.email_verified_at">
            <p>{{ $t('dashboard.message-unverified') }}</p>
            <p v-if="!resent" class="block mt-6">
              <a href="#" class="px-6 py-2 rounded-tr-2xl transition rounded-bl-2xl cursor-pointer hover:shadow-xl bg-oh-gradient text-white hover:bg-red-900" @click="verifyEmail">{{ $t('dashboard.verify-address') }}</a>
            </p>
            <p v-else class="block mt-4 italic">{{ $t('dashboard.verify-resent') }}</p>
          </div>
          <div v-else-if="user.status === 'new' && !hasValidSubscription">
            {{ $t('dashboard.message-new') }}
          </div>
          <div v-else-if="user.status === 'new' && hasValidSubscription">
            {{ $t('dashboard.message-verifying') }}
          </div>
          <div v-else>{{ $t('dashboard.message-status') }}</div>
        </div>

        <card class="w-full">
          <template slot="title">
            <div class="flex">
              <span style="margin-top: 0.16rem;">{{ $t('dashboard.welcome-headline') }}</span>
              <img src="/images/logo_wordmark.png" class="ml-2 h-7 inline-block" alt="OakHost">
            </div>
          </template>
          {{ $t('dashboard.welcome-text', {name: user.display_name}) }}
        </card>

        <card v-if="products.length > 0">
          <template slot="title">
            {{ $t('dashboard.server') }}
          </template>

          <p class="mb-5">{{ $t('dashboard.server-teaser') }}</p>
          <div v-for="product in products" :key="product.id">
            <server-entry :server="product"></server-entry>
          </div>
        </card>

        <card>
          <template slot="title">
            {{ $t('dashboard.subscription') }}
          </template>

          <p class="mb-5">{{ $t('dashboard.subscription-teaser') }}</p>
          <div v-for="subscription in subscriptions" :key="subscription.id">
            <subscription-entry :subscription="subscription"></subscription-entry>
          </div>
          <div v-if="subscriptions.length === 0">
            <p>{{ $t('dashboard.no-subscriptions') }}</p>
            <div class="block mt-6">
              <oh-button type="primary" href="https://www.oakhost.net/mac-mini-hosting#pricing">
                {{ $t('dashboard.check-products') }}
              </oh-button>
            </div>
          </div>
          <div class="mt-3" v-else>
            <oh-button type="primary" href="https://www.oakhost.net/mac-mini-hosting#pricing">{{ $t('dashboard.products-cta') }}</oh-button>
          </div>
        </card>
      </div>
    </div>
  </main-layout>
</template>

<script>

import MainLayout from "@/components/Layout/MainLayout";
import Card from "@/components/Card";
import {globalData} from "@/globalData";
import OhButton from "@/components/OhButton";
import SubscriptionEntry from "@/components/Entries/SubscriptionEntry";
import ServerEntry from "@/components/Entries/ServerEntry";
import {api} from "@/api";

export default {
  components: {
    ServerEntry,
    SubscriptionEntry,
    OhButton,
    Card,
    MainLayout
  },
  mounted() {
    globalData.activeRoute = 'dashboard';
  },
  data() {
    return {
      resent: false
    };
  },
  methods: {
    async verifyEmail() {
      this.resent = true;
      await api.post('/register/resend');
    }
  },
  computed: {
    user() {
      return globalData.user;
    },
    products() {
      return (globalData.products || []).filter(p => p.type !== 'smb_storage').sort((a, b) => a.settings.name.localeCompare(b.settings.name));
    },
    subscriptions() {
      return (globalData.subscriptions || []).filter(s => s.status !== 'cancelled');
    },
    hasValidSubscription() {
      for (let k in globalData.subscriptions) {
        if (['new', 'active'].includes(globalData.subscriptions[k].status)) {
          return true;
        }
      }

      return false;
    }
  }
}
</script>
