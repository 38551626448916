<template>
  <main-layout>
    <template slot="title">
      {{ $t('nav.storage') }}
    </template>
    <div class="min-h-full">
      <div class="m-auto max-w-7xl">
        <card class="w-full">
          <template slot="title">
            <div class="flex">
              {{ $t('storage.headline') }}
            </div>
          </template>
          <div v-if="!hasValidSubscription">{{ $t('storage.no-subscription') }}</div>
          <div v-else>
            <p class="mb-2">{{ $t('storage.description') }}</p>
            <p class="mb-2">{{ $t('storage.access') }}</p>
            <p>
              <a href="https://docs.oakhost.net/network-storage" target="_blank" class="underline">
                {{ $t('storage.docs') }}<i class="fas fa-external-link-alt ml-2"></i>
              </a>
            </p>
          </div>
        </card>
        <storage-details class="w-full" v-for="product in products" :key="product.id" :product="product"></storage-details>
        <card class="w-full" v-if="hasValidSubscription">
          <template slot="title">
            <div class="flex">
              {{ $t('storage.no-storage-title') }}
            </div>
          </template>
          <div v-if="canManageSubscriptions">
            <p class="mb-2">{{ $t('storage.no-storage-description') }}</p>
            <ul>
              <li v-for="storageProduct in availableStorageProducts" :key="storageProduct.id"
                  @click="selectedProduct = storageProduct"
                  class="flex mt-4 rounded-xl px-6 py-4 cursor-pointer border-gray-300 border hover:bg-gray-100 transition">
                <span class="flex-1 mr-2">{{ storageProduct.name }}</span>
                <span>
                  {{ storageProduct.pricing.monthly.gross_interval.value }} € {{ $t('storage.per-month') }}
                  ({{ $t('storage.incl-vat', [storageProduct.pricing.monthly.vat_interval.percentage]) }})
                </span>
              </li>
            </ul>
          </div>
          <div v-else>
            <p>{{ $t('storage.no-storage-description-permission') }}</p>
          </div>
        </card>
      </div>
      <modal :show="selectedProduct" @close="selectedProduct = null">
        <template slot="title" v-if="selectedProduct">
          {{ $t('storage.order.title', [selectedProduct.name]) }}
        </template>
        <div v-if="error" class="bg-red-100 p-3 mb-6">
          {{ error }}
        </div>
        <div v-if="selectedProduct">
          {{ $t('storage.order.info', [selectedProduct.name]) }}
          <h3 class="text-lg font-bold mt-6">{{ $t('storage.order.summary') }}</h3>
          <div class="md:flex items-end">
            <div class="flex-1">
              <table class="mt-3 ml-2">
                <tr>
                  <td class="pr-2 md:pr-8 pb-3">
                    <p>1x {{ selectedProduct.name }}</p>
                    <p class="italic">{{ $t('storage.order.terms') }}</p>
                  </td>
                  <td class="text-right align-top">{{ selectedProduct.pricing.monthly.gross_interval.value }} €</td>
                </tr>
                <tr class="border-t border-gray-200">
                  <td class="pt-3">{{ $t('storage.order.sum-net') }}</td>
                  <td class="text-right pt-3">{{ selectedProduct.pricing.monthly.net_interval.value }} €</td>
                </tr>
                <tr>
                  <td class="pb-3">{{ $t('storage.order.sum-vat') }}</td>
                  <td class="text-right">{{ selectedProduct.pricing.monthly.vat_interval.value }} €</td>
                </tr>
                <tr class="border-t border-gray-200 font-bold">
                  <td class="pt-3">{{ $t('storage.order.sum-total') }}</td>
                  <td class="text-right pt-3">{{ selectedProduct.pricing.monthly.gross_interval.value }} €</td>
                </tr>
              </table>
              <p class="pt-6">{{ $t('storage.order.default-payment-method') }}</p>
            </div>
            <div class="mt-6">
              <oh-button :type="loading ? 'disabled' : 'primary'" @click="orderProduct">
                <span v-if="!loading">{{ $t('storage.order.confirm') }}</span>
                <span v-else>{{ $t('storage.order.loading') }}</span>
              </oh-button>
            </div>
          </div>
        </div>
      </modal>
    </div>
  </main-layout>
</template>

<script>

import MainLayout from "@/components/Layout/MainLayout";
import Card from "@/components/Card";
import {globalData} from "@/globalData";
import {api} from "@/api";
import Modal from "@/components/Modal";
import OhButton from "@/components/OhButton";
import {StripeElementsPlugin} from "@vue-stripe/vue-stripe";
import Vue from "vue";
import * as Sentry from "@sentry/vue";
import StorageDetails from "../components/Storage/StorageDetails";

export default {
  components: {
    StorageDetails,
    OhButton,
    Modal,
    Card,
    MainLayout
  },
  async mounted() {
    globalData.activeRoute = 'storage'

    this.initializeStripe()

    let response = await api.get('products')
    this.availableStorageProducts = response.data.filter(p => p.type === 'smb_storage')
  },
  data() {
    return {
      availableStorageProducts: [],
      selectedProduct: null,
      loading: false,
      error: null
    };
  },
  methods: {
    async orderProduct() {
      this.loading = true
      this.error = null
      try {
        let response = await api.post('user/subscriptions', {
          product_id: this.selectedProduct.id
        })
        let subscription = response.data;
        if (['requires_action', 'requires_confirmation'].includes(subscription.payment_intent.status)) {
          // 3d secure/SEPA confirmation
          this.requireConfirmation(subscription);
        } else {
          this.paymentSucceeded(subscription);
        }
      } catch (e) {
        Sentry.captureException(e)
        if (e.response.data.errors) {
          let errors = e.response.data.errors;
          this.error = errors[Object.keys(errors)[0]][0];
        } else {
          this.error = 'An unknown error occurred. Please contact our support at support@oakhost.net';
        }
        this.loading = false;
      }
    },
    requireConfirmation(subscription) {
      let confirmationCallback = (result) => {

        if (result.error) {
          this.error = result.error.message;
          this.loading = false;
        } else if (result.paymentIntent.status === 'processing') {
          // sepa debit doesn't succeed immediately
          this.paymentSucceeded(subscription);
        } else if (result.paymentIntent.status === 'succeeded') {
          // credit card succeeds immediately
          this.paymentSucceeded(subscription);
        } else {
          // should not happen
          this.error = 'Unknown error. Please contact support.'
        }
      };

      if (subscription.pm_type === 'sepa_debit') {
        return this.$stripe.confirmSepaDebitPayment(subscription.payment_intent.client_secret, {
          payment_method: subscription.pm_id
        }).then(confirmationCallback);
      } else {
        return this.$stripe.confirmCardPayment(subscription.payment_intent.client_secret, {
          payment_method: subscription.pm_id
        }).then(confirmationCallback);
      }
    },
    async paymentSucceeded(subscription) {
      try {
        await api.post('checkout/accept/' + subscription.id);

        this.$router.push({name: 'Subscriptions', params: {id: subscription.id, state: 'thanks'}})
      } catch (error) {
        this.error = error
      }
    },
    initializeStripe() {
      if (!this.$stripe) {
        const plugin = document.createElement("script");
        plugin.setAttribute("src", "https://js.stripe.com/v3/");
        plugin.async = false;
        plugin.addEventListener('load', () => {
          Vue.use(StripeElementsPlugin, {pk: process.env.VUE_APP_STRIPE_PUBLIC_KEY});
        });
        document.head.appendChild(plugin);
      }
    },
  },
  computed: {
    user() {
      return globalData.user;
    },
    products() {
      return globalData.products.filter(p => p.type === 'smb_storage') || [];
    },
    subscriptions() {
      return globalData.subscriptions || [];
    },
    canManageSubscriptions() {
      return this.user && (this.user.account_owner || this.user.permissions.filter(p => p.type === 'subscriptions').length > 0)
    },
    hasValidSubscription() {
      for (let k in globalData.subscriptions) {
        if (['new', 'active'].includes(globalData.subscriptions[k].status)) {
          return true;
        }
      }

      return false;
    }
  }
}
</script>
