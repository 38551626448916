import axios from 'axios';
import {session} from "@/sessionStore";
import i18n from "@/i18n";
import {globalData} from "@/globalData";

export const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Authorization': 'Bearer ' + session.getToken(),
        'X-Lang': i18n.locale || 'en',
        'Accept': 'application/json'
    }
});

api.interceptors.request.use(function (config) {
    globalData.loading++;
    return config;
}, function (error) {
    globalData.loading--;
    return Promise.reject(error);
});

api.interceptors.response.use(function (response) {
    globalData.loading--;
    return response;
}, function (error) {
    globalData.loading--;
    return Promise.reject(error);
});