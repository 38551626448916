<template>
  <div>
    <div class="flex text-sm">
      <label :for="id" class="flex-1 text-gray-600 font-bold tracking-tight">{{ label }}</label>
      <span class="text-gray-500">
        <slot name="help"></slot>
      </span>
    </div>
    <div><slot name="after-label"></slot></div>
    <div class="relative">
      <select
          :id="id"
          :name="id"
          class="appearance-none w-full outline-none block mt-1 px-5 py-3 text-gray-800 border border-gray-300 rounded-lg bg-white"
          :value="value"
          :readonly="readonly"
          :disabled="readonly"
          :class="[
              readonly ? 'bg-gray-200' : 'focus:border-oh-blue focus:shadow-lg'
          ]"
          @input="input"
      >
        <slot></slot>
      </select>
      <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
        <i class="fas fa-chevron-down text-gray-800"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {required: true},
    label: String,
    id: String,
    readonly: {default: false}
  },
  methods: {
    input(event) {
      this.$emit('input', event.target.value);
    }
  }
}
</script>

<style scoped>

</style>