<template>
  <card class="w-full">
    <template slot="title">
      {{ $t('account.locale-title') }}
    </template>

    <div v-if="message"
         class="p-6 mb-5 font-bold" :class="type === 'error' ? 'bg-red-100' : 'bg-green-100'"
    >
      {{ message }}
    </div>

    <p class="mb-5">{{ $t('account.locale-teaser') }}</p>

    <form @submit="updateLocale">
      <div class="w-full mb-4">
        <oh-select :label="$t('account.locale')" v-model="user.locale">
          <option value="en">English</option>
          <option value="de">Deutsch</option>
        </oh-select>
      </div>

      <div class="mt-8 mb-3">
        <oh-button type="primary" @click="updateLocale">{{ loading ? $t('account.loading') : $t('account.update-locale') }}</oh-button>
      </div>
    </form>
  </card>
</template>

<script>
import OhButton from "@/components/OhButton";
import Card from "@/components/Card";
import {api} from "@/api";
import {globalData} from "@/globalData";
import OhSelect from "@/components/OhSelect";
import i18n from "@/i18n";

export default {
  components: {
    OhSelect,
    OhButton,
    Card
  },
  data() {
    return {
      message: null,
      type: 'success',
      loading: false
    }
  },
  methods: {
    async updateLocale() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.message = null;
      try {
        let response = await api.put('user/locale', {
          locale: this.user.locale
        });

        this.loading = false;
        globalData.user = response.data;
        i18n.locale = globalData.user.locale;
        this.type = 'success';
        this.message = this.$t('account.success');
      } catch (error) {
        this.loading = false;
        this.type = 'error';
        if (error.response.data && error.response.data.errors) {
          let errors = error.response.data.errors;
          this.message = errors[Object.keys(errors)[0]][0];
        } else {
          this.message = 'Unknown error. Please try again or contact support.';
        }
      }
    }
  },
  computed: {
    user() {
      return globalData.user;
    }
  }
}
</script>

<style scoped>

</style>