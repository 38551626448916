<template>
  <main-layout>
    <template slot="title">
      {{ $t('nav.invoices') }}
    </template>
    <div class="min-h-full">
      <div class="m-auto max-w-7xl xl:flex xl:space-x-10">
        <card class="w-full" v-if="userCanSeeInvoices">
          <template slot="title">
            {{ $t('invoices.invoice-headline') }}
          </template>
         <p class="mb-12">{{ $t('invoices.invoice-teaser') }}</p>
          <oh-button type="primary" button-class="block w-full md:inline" @click="openInvoices">{{ $t('invoices.invoice-button') }}</oh-button>
        </card>
        <card class="w-full">
          <template slot="title">
            {{ $t('invoices.subscriptions-headline') }}
          </template>
          <p class="mb-8">{{ $t('invoices.subscriptions-teaser') }}</p>
          <div v-for="subscription in subscriptions" :key="subscription.id">
            <subscription-entry :subscription="subscription"></subscription-entry>
          </div>
          <div v-if="subscriptions.length === 0">
            <p>{{ $t('dashboard.no-subscriptions') }}</p>
            <div class="block mt-6">
              <oh-button type="primary" href="https://www.oakhost.net/#pricing">{{ $t('dashboard.check-products') }}</oh-button>
            </div>
          </div>
        </card>
      </div>
    </div>
  </main-layout>
</template>

<script>

import MainLayout from "@/components/Layout/MainLayout";
import Card from "@/components/Card";
import {api} from "@/api";
import OhButton from "@/components/OhButton";
import {globalData} from "@/globalData";
import SubscriptionEntry from "@/components/Entries/SubscriptionEntry";

export default {
  components: {
    SubscriptionEntry,
    OhButton,
    Card,
    MainLayout
  },
  mounted() {
    globalData.activeRoute = 'invoices';
  },
  methods: {
    async openInvoices() {
      let response = await api.post('user/payments/manage');
      window.location.href = response.data.url;
    }
  },
  computed: {
    userCanSeeInvoices() {
      return ['active', 'verified', 'disabled'].includes(this.user.status)
       && this.subscriptions.filter(s => ['cancelled', 'active', 'paused'].includes(s.status)).length > 0;
    },
    user() {
      return globalData.user;
    },
    subscriptions() {
      return globalData.subscriptions || [];
    }
  }
}
</script>
