import Vue from "vue";

export const globalData = new Vue({
    data: {
        user: {},
        products: [],
        subscriptions: [],
        loading: 0,
        showError: false,
        activeRoute: 'dashboard'
    },
    watch: {
        loading() {
            if (this.loading < 0) {
                this.loading = 0;
            }
        }
    }
});