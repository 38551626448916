<template>
  <card class="w-full">
    <template slot="title">
      {{ $t('monitoring.latest-events') }}
    </template>
    <p>{{ $t('monitoring.events-teaser') }}</p>

    <div class="mt-5 border-t border-gray-200 table w-full" v-if="events.length">
      <div class="table-row">
        <div class="table-cell p-5 font-bold border-b border-gray-200">{{ $t('monitoring.events.monitor') }}</div>
        <div class="table-cell p-5 font-bold border-b border-gray-200">{{ $t('monitoring.events.status-change') }}</div>
        <div class="table-cell p-5 font-bold border-b border-gray-200">{{ $t('monitoring.events.time') }}</div>
      </div>
      <div class="table-row" v-for="event in resolvableEvents" :key="event.id"
           :class="event.status === 'down' ? 'bg-red-50' : ''"
      >
        <div class="table-cell p-5 border-b border-gray-200">{{ monitorFor(event).name }}</div>
        <div class="table-cell p-5 border-b border-gray-200">
          <status-badge :status="event.status"></status-badge>
        </div>
        <div class="table-cell p-5 border-b border-gray-200">{{ event.created_at | formatDateTime }}</div>
      </div>
    </div>
    <div class="w-full" v-if="this.events.length > LATEST_EVENT_LIMIT">
      <div class="p-5 font-bold border-b border-gray-200">...</div>
    </div>
    <div v-if="!events.length && !loading" class="text-center mt-5 p-10 w-full bg-blue-100 font-bold">
      {{ $t('monitoring.no-events') }}
    </div>
    <div v-if="loading" class="italic mt-10">
      {{ $t('main.loading') }}
    </div>
  </card>
</template>

<script>
import Card from "@/components/Card";
import {api} from "@/api";
import StatusBadge from "@/components/Monitoring/StatusBadge";

export default {
  props: {
    monitors: {required: true}
  },
  data() {
    return {
      events: [],
      loading: true,
      interval: null,
      LATEST_EVENT_LIMIT: 50
    }
  },
  mounted() {
    this.loadEvents();
    this.interval = setInterval(this.loadEvents, 60000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    async loadEvents() {
      let response = await api.get('monitoring/event');
      this.events = response.data;
      this.loading = false;
    },
    monitorFor(event) {
      return this.monitors.filter(m => m.id === event.monitor_id)[0] || null;
    }
  },
  computed: {
    resolvableEvents() {
      return this.events.filter(e => this.monitorFor(e)).splice(0, this.LATEST_EVENT_LIMIT);
    }
  },
  components: {
    StatusBadge,
    Card
  },
}
</script>

<style scoped>

</style>