<template>
  <modal :show="show" @close="close" v-if="dirtyMonitor">
    <template slot="title">{{ isNew ? $t('monitoring.add') : $t('monitoring.edit') }}</template>
    <form @submit="save">

      <div v-if="message" class="p-6 mb-5 font-bold bg-red-100">
        {{ message }}
      </div>

      <div class="mb-5">
        <oh-input :label="$t('monitoring.monitor-edit.name')"
                  :placeholder="$t('monitoring.monitor-edit.name-placeholder')" v-model="dirtyMonitor.name"></oh-input>
      </div>

      <div class="mb-5">
        <oh-select :label="$t('monitoring.monitor-edit.interval')" v-model="dirtyMonitor.interval">
          <option :value="120">{{ $t('monitoring.monitor-edit.minutes', [2]) }}</option>
          <option :value="300">{{ $t('monitoring.monitor-edit.minutes', [5]) }}</option>
          <option :value="600">{{ $t('monitoring.monitor-edit.minutes', [10]) }}</option>
          <option :value="900">{{ $t('monitoring.monitor-edit.minutes', [15]) }}</option>
          <option :value="1800">{{ $t('monitoring.monitor-edit.minutes', [30]) }}</option>
          <option :value="3600">{{ $t('monitoring.monitor-edit.hour') }}</option>
          <option :value="7200">{{ $t('monitoring.monitor-edit.hours', [2]) }}</option>
        </oh-select>
      </div>

      <div class="mb-5">
        <oh-select :label="$t('monitoring.monitor-edit.type')" v-model="dirtyMonitor.type">
          <option value="http">{{ $t('monitoring.monitor-edit.http') }}</option>
          <option value="ping">{{ $t('monitoring.monitor-edit.ping') }}</option>
          <option value="port">{{ $t('monitoring.monitor-edit.port') }}</option>
        </oh-select>
      </div>

      <div class="mb-5">
        <oh-input :label="$t('monitoring.monitor-edit.url')" :placeholder="urlPlaceholder"
                  v-model="dirtyMonitor.uri"></oh-input>
      </div>

      <div class="mb-5" v-if="dirtyMonitor.type === 'http'">
        <oh-select :label="$t('monitoring.monitor-edit.method')" v-model="dirtyMonitor.settings.method">
          <option value="GET">GET</option>
          <option value="POST">POST</option>
          <option value="PUT">PUT</option>
          <option value="PATCH">PATCH</option>
          <option value="DELETE">DELETE</option>
        </oh-select>
      </div>

      <div class="mb-5" v-if="dirtyMonitor.type === 'port'">
        <oh-input type="number" :label="$t('monitoring.monitor-edit.port-label')"
                  v-model="dirtyMonitor.settings.port"></oh-input>
      </div>

      <div class="mb-5">
        <oh-select :readonly="unselectedAlerts.length === 0" :label="$t('monitoring.monitor-edit.alerts')"
                   v-model="selectedAlert" @input="alertSelected">
          <template slot="help">{{ $t('monitoring.monitor-edit.alerts-help') }}</template>
          <template slot="after-label">
            <div v-for="(alertId, k) in dirtyMonitor.alert_ids" :key="k">
              <div v-if="alertFor(alertId)" class="border-t border-gray-300 bg-gray-50 p-3 my-2 flex">
                <div class="flex-1">
                  {{ alertFor(alertId).name }}
                  <span v-if="alertFor(alertId).type === 'email'">- {{ alertFor(alertId).settings.email }}</span>
                </div>
                <div class="cursor-pointer" @click="alertRemoved(alertId)"><i class="fas fa-times"></i></div>
              </div>
            </div>
          </template>
          <option :value="null">+ Add Alert</option>
          <option :value="alert.id" v-for="alert in unselectedAlerts" :key="alert.id">{{ alert.name }}</option>
        </oh-select>
      </div>

      <div class="mt-10 mb-2 flex">
        <div class="flex-1">
          <oh-button type="primary" @click="save">{{ $t('monitoring.monitor-edit.save') }}</oh-button>
        </div>
        <div>
          <oh-button v-if="!isNew" @click="confirmDelete" type="danger">
            {{ $t('monitoring.monitor-edit.delete') }}
          </oh-button>

          <oh-button v-else @click="close">{{ $t('monitoring.monitor-edit.cancel') }}</oh-button>
        </div>
      </div>
      <input type="submit" class="hidden" @click="save">
    </form>
  </modal>
</template>

<script>
import Modal from "@/components/Modal";
import OhInput from "@/components/OhInput";
import OhSelect from "@/components/OhSelect";
import OhButton from "@/components/OhButton";
import {api} from "@/api";

export default {
  props: {
    show: {required: true},
    monitor: {required: true},
    alerts: {required: true}
  },
  data() {
    return {
      selectedAlert: null,
      dirtyMonitor: null,
      message: null,
    }
  },
  mounted() {
    this.reset();
  },
  methods: {
    async save() {
      this.message = null;

      // Remove all alert ids without an alert model assigned
      this.dirtyMonitor.alert_ids = this.dirtyMonitor.alert_ids.filter(id => this.alertFor(id));

      try {
        let response;
        if (this.isNew) {
          response = await api.post('monitoring/monitor', this.dirtyMonitor);
        } else {
          response = await api.put('monitoring/monitor/' + this.monitor.id, this.dirtyMonitor);
        }
        this.$emit('created', response.data);
        this.close();
      } catch (e) {
        if (e.response.data.errors) {
          let errors = e.response.data.errors;
          this.message = errors[Object.keys(errors)[0]][0];
        } else {
          this.message = 'Server Error. Please try again later.';
        }
      }
    },
    async confirmDelete() {
      if (!confirm(this.$t('monitoring.monitor-edit.delete-confirm'))) {
        return;
      }

      await api.delete('monitoring/monitor/' + this.monitor.id);
      this.$emit('deleted');
      this.close();
    },
    close() {
      this.$emit('close');
      this.reset();
    },
    reset() {
      this.dirtyMonitor = JSON.parse(JSON.stringify(this.monitor));
      this.selectedAlert = null;
    },
    alertSelected() {
      if (!this.selectedAlert) {
        return;
      }
      this.dirtyMonitor.alert_ids.push(this.selectedAlert);
      this.selectedAlert = null;
    },
    alertRemoved(id) {
      this.dirtyMonitor.alert_ids.splice(this.dirtyMonitor.alert_ids.indexOf(id), 1);
    },
    alertFor(id) {
      return this.alerts.filter(a => a.id === id)[0] || null;
    }
  },
  computed: {
    isNew() {
      return !this.monitor.id;
    },
    urlPlaceholder() {
      if (this.dirtyMonitor.type === 'http') {
        return 'https://my-server.com';
      }

      return 'my-server.com';
    },
    unselectedAlerts() {
      return this.alerts.filter(a => !this.dirtyMonitor.alert_ids.includes(a.id));
    }
  },
  watch: {
    monitor() {
      this.reset();
    },
  },
  components: {
    OhButton,
    OhSelect,
    OhInput,
    Modal,
  }
}
</script>

<style scoped>

</style>