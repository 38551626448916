<template>
  <main-layout>
    <template slot="title">
      {{ $t('nav.monitoring') }}
    </template>
    <div class="min-h-full" v-if="user.email_verified_at && user.status === 'active'">
      <div class="xl:flex xl:space-x-10">
        <active-monitors :alerts="alerts" @monitorsUpdated="updateMonitors" class="xl:w-2/3"></active-monitors>
        <alerts :alerts="alerts" :loading="loadingAlerts" @refreshAlerts="updateAlerts" class="xl:w-500px"></alerts>
      </div>
      <latest-events :monitors="monitors"></latest-events>
    </div>
    <div class="min-h-full" v-else>
      <div class="p-6 mb-5 font-bold bg-blue-100">
        {{ $t('monitoring.unavailable') }}
      </div>
    </div>
  </main-layout>
</template>

<script>

import MainLayout from "@/components/Layout/MainLayout";
import ActiveMonitors from "@/components/Monitoring/ActiveMonitors";
import Alerts from "@/components/Monitoring/Alerts";
import {api} from "@/api";
import LatestEvents from "@/components/Monitoring/LatestEvents";
import {globalData} from "@/globalData";

export default {
  data() {
    return {
      alerts: [],
      monitors: [],
      loadingAlerts: true
    }
  },
  mounted() {
    this.updateAlerts();
    globalData.activeRoute = 'monitoring';
  },
  methods: {
    async updateAlerts() {
      let response = await api.get('monitoring/alert');
      this.alerts = response.data;
      this.loadingAlerts = false;
    },
    updateMonitors(monitors) {
      this.monitors = monitors;
    }
  },
  computed: {
    user() {
      return globalData.user;
    }
  },
  components: {
    LatestEvents,
    Alerts,
    ActiveMonitors,
    MainLayout
  }
}
</script>
