import { render, staticRenderFns } from "./IpTable.vue?vue&type=template&id=1dd4da2e&scoped=true&"
import script from "./IpTable.vue?vue&type=script&lang=js&"
export * from "./IpTable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dd4da2e",
  null
  
)

export default component.exports