<template>
  <card class="w-full">
    <template slot="title">
      {{ $t('account.password-title') }}
    </template>

    <div v-if="message"
         class="p-6 mb-5 font-bold" :class="type === 'error' ? 'bg-red-100' : 'bg-green-100'"
    >
      {{ message }}
    </div>
    <p class="mb-5">{{ $t('account.password-teaser') }}</p>

    <form @submit="updatePassword">
      <div class="w-full mb-4">
        <oh-input :label="$t('account.current-password')" v-model="currentPassword" id="currentPassword" type="password"></oh-input>
      </div>
      <div class="w-full mb-4">
        <oh-input :label="$t('account.password')" v-model="password" id="password" type="password"></oh-input>
      </div>
      <div class="w-full mb-4">
        <oh-input :label="$t('account.password-confirmation')" v-model="passwordConfirmation" id="passwordConfirmation" type="password"></oh-input>
      </div>

      <div class="mt-8 mb-3">
        <oh-button type="primary" @click="updatePassword">{{ loading ? $t('account.loading') : $t('account.update-password') }}</oh-button>
      </div>
    </form>
  </card>
</template>

<script>
import OhButton from "@/components/OhButton";
import OhInput from "@/components/OhInput";
import Card from "@/components/Card";
import {api} from "@/api";
import {globalData} from "@/globalData";

export default {
  components: {
    OhButton,
    OhInput,
    Card
  },
  data() {
    return {
      message: null,
      type: 'success',
      loading: false,
      currentPassword: null,
      password: null,
      passwordConfirmation: null
    }
  },
  methods: {
    async updatePassword() {
      if (this.loading) {
        return;
      }

      this.loading = true;
      this.message = null;
      try {
        await api.put('user/password', {
          'current_password': this.currentPassword,
          'password': this.password,
          'password_confirmation': this.passwordConfirmation
        });

        this.loading = false;
        this.type = 'success';
        this.message = this.$t('account.password-success');
      } catch (error) {
        this.loading = false;
        this.type = 'error';
        if (error.response.data && error.response.data.errors) {
          let errors = error.response.data.errors;
          this.message = errors[Object.keys(errors)[0]][0];
        } else {
          this.message = 'Unknown error. Please try again or contact support.';
        }
      }
    }
  },
  computed: {
    user() {
      return globalData.user;
    }
  }
}
</script>

<style scoped>

</style>