<template>
  <main-layout :loading="loading">
    <template slot="title">
      {{ $t('servers.headline') }} {{ !loading ? product.settings.name : 'Loading' }}
    </template>
    <div v-if="!loading" class="min-h-full">
      <div v-if="user.email_verified_at && user.status === 'active'" class="m-auto max-w-7xl">
        <tab-card :tabs="tabs" initial-selection="settings" @selected="switchTab">
          <div class="mt-4">
            <div v-if="currentTab === 'settings'">
              <server-settings :product="product"></server-settings>
            </div>
            <div v-if="currentTab === 'network'">
              <server-network :product="product"></server-network>
            </div>
            <div v-if="currentTab === 'firewall'">
              <server-firewall :server="product"></server-firewall>
            </div>
            <div v-if="currentTab === 'access'">
              <server-access :product="product"></server-access>
            </div>
            <div v-if="currentTab === 'statistics'">
              <server-statistics :product="product"></server-statistics>
            </div>
          </div>
        </tab-card>

        <card class="flex-1" v-if="subscription && !subscription.renewal_active">
          <template slot="title">
            <span class="text-oh-red">{{ $t('subscriptions.extend-title') }}</span>
          </template>

          <p class="mb-8">{{ $t('subscriptions.extend-teaser') }}</p>
          <p class="mb-5"><oh-button type="primary" @click="contactForm">{{ $t('subscriptions.contact-renew') }}</oh-button></p>
        </card>
      </div>
      <div v-else class="m-auto max-w-7xl">
        <div class="p-6 mb-5 font-bold bg-blue-100">
          {{ $t('servers.unavailable') }}
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>

import MainLayout from "@/components/Layout/MainLayout";
import {globalData} from "@/globalData";
import {api} from "@/api";
import TabCard from "@/components/TabCard";
import ServerSettings from "@/components/Servers/ServerSettings";
import ServerFirewall from "@/components/Servers/ServerFirewall";
import ServerStatistics from "@/components/Servers/ServerStatistics";
import ServerNetwork from "@/components/Servers/ServerNetwork";
import ServerAccess from "@/components/Servers/ServerAccess";
import Card from "@/components/Card";
import OhButton from "@/components/OhButton";

export default {
  props: {
    id: {
      required: true,
    }
  },
  data() {
    return {
      product: {},
      subscription: null,
      status: {},
      currentTab: 'settings',
      tabs: {
        settings: {icon: 'fas fa-sliders-h', name: this.$t('servers.settings')},
        access: {icon: 'fas fa-keyboard', name: this.$t('servers.access')},
        network: {icon: 'fas fa-network-wired', name: this.$t('servers.network')},
        firewall: {icon: 'fas fa-shield-alt', name: this.$t('servers.firewall')},
        statistics: {icon: 'fas fa-chart-area', name: this.$t('servers.statistics')}
      }
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      globalData.activeRoute = 'server#' + this.id;

      try {
        this.product = (await api.get('user/products/' + this.id)).data;
        this.subscription = (await api.get('user/products/' + this.id + '/subscription')).data;
      } catch (e) {
        if ([404, 403].includes(e.response.status)) {
          globalData.showError = e.response.status;
        }
      }
    },
    contactForm() {
      this.$router.push({name: 'Support'});
    },
    switchTab(tab) {
      this.currentTab = tab;
    }
  },
  computed: {
    loading() {
      return typeof this.product.id === 'undefined';
    },
    user() {
      return globalData.user;
    }
  },
  watch: {
    id() {
      window.location.reload()
    }
  },
  components: {
    OhButton,
    Card,
    ServerAccess,
    ServerNetwork,
    ServerStatistics,
    ServerFirewall,
    ServerSettings,
    TabCard,
    MainLayout
  },
}
</script>
