<template>
  <div id="app" class="min-h-screen font-sans bg-gray-100">
    <div
        class="fixed top-0 right-0 left-0 h-2 loading-bar z-50 shadow-md transition-opacity duration-300 ease-in-out opacity-0"
        :class="{'opacity-100': showLoadingBar}">
    </div>
    <div v-if="loaded">
      <div class="md:flex md:min-h-screen">
        <div class="bg-oh-gradient text-white w-full md:w-sidebar absolute top-0 z-20 md:static border-r border-gray-800" v-if="!$route.query.nosidebar">
          <navigation></navigation>
        </div>
        <div class="md:flex-1 md:mt-0" :class="{ 'mt-20': !$route.query.nosidebar }">
          <user-notice></user-notice>
          <router-view v-if="!showError"/>
          <not-found v-if="showError === 404"></not-found>
          <access-denied v-if="showError === 403"></access-denied>
        </div>
      </div>
      <survey-question></survey-question>
    </div>
    <div v-else class="min-h-screen pt-20 text-center bg-gray-100">
      Loading... please wait...
    </div>
  </div>
</template>

<style lang="scss">
</style>
<script>
import Navigation from "@/components/Layout/Navigation";
import {api} from "@/api";
import {globalData} from "@/globalData";
import i18n from "@/i18n";
import {session} from "@/sessionStore";
import NotFound from "@/views/NotFound";
import AccessDenied from "@/views/AccessDenied";
import SurveyQuestion from "./components/SurveyQuestion";
import UserNotice from "./components/UserNotice";

export default {
  data() {
    return {
      user: {},
      loaded: false,
      showLoadingBar: true
    };
  },
  mounted() {
    this.fetchUser();
    this.fetchProducts();
    this.fetchSubscriptions();
  },
  methods: {
    async fetchUser() {
      try {
        globalData.user = (await api.get('user')).data;
        i18n.locale = globalData.user.locale;
        this.loaded = true;
      } catch (err) {
        session.deleteToken();
        window.location.href = process.env.VUE_APP_WEBSITE_URL + 'login';
      }
    },
    async fetchProducts() {
      globalData.products = (await api.get('user/products')).data;
    },
    async fetchSubscriptions() {
      globalData.subscriptions = (await api.get('user/subscriptions')).data;
    }
  },
  computed: {
    isLoading() {
      return globalData.loading > 0;
    },
    showError() {
      return globalData.showError;
    }
  },
  watch: {
    isLoading() {
      setTimeout(() => {
        this.showLoadingBar = this.isLoading;
      }, 500);
    },
    $route() {
      // on route change, reset error message
      globalData.showError = false;
    }
  },
  components: {UserNotice, SurveyQuestion, AccessDenied, NotFound, Navigation}
}
</script>