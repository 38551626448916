const TOKEN_COOKIE = 'session_token';
import Cookies from 'js-cookie';

export const session = new class SessionStore {
    setToken(token, hours = 5) {
        this.token = token;

        Cookies.set(TOKEN_COOKIE, token, {
            expires: hours / 24,
            ...this.getAttributes()
        });
    }

    getToken() {
        if (this.token) {
            return this.token;
        }

        return Cookies.get(TOKEN_COOKIE);
    }

    deleteToken() {
        Cookies.remove(TOKEN_COOKIE, this.getAttributes());
    }

    getAttributes() {
        let domainName = /^(.*\.)?(.*\..*)$/.exec(document.domain);

        return {
            domain: domainName[2],
            secure: location.protocol === 'https:',
            sameSite: 'Lax',
        };
    }
}
