<template>
  <card class="w-full">
    <template slot="title">
      <span class="block md:flex">
        <span class="block flex-1 mb-4 md:mb-0">{{ $t('monitoring.monitors') }}</span>
        <span class="block">
          <oh-button type="primary" class="text-sm" @click="addUpdateMonitor">
            <i class="fas fa-plus mr-1"></i> {{ $t('monitoring.add') }}
          </oh-button>
        </span>
      </span>
    </template>
    <p>{{ $t('monitoring.monitors-teaser') }}</p>

    <div class="mt-5 border-t border-gray-200 w-full" v-if="monitors.length" ref="monitorContainer">
      <div v-for="entry in monitors" :key="entry.id"
           class="hover:bg-gray-100 border-b border-gray-200 cursor-pointer w-full"
           @click="addUpdateMonitor(entry)">
        <div class="md:flex py-5 md:py-0">
          <div class="font-bold md:p-5">
            {{ entry.name }}
          </div>
          <div class="text-gray-400 text-sm md:text-right md:p-5 mt-1 flex-1">
            <span v-if="entry.last_checked_at">
              {{ $t('monitoring.last-check-at') }}
              {{ entry.last_checked_at | formatDateTime }}
            </span>
            <span v-else>
              {{ $t('monitoring.not-checked') }}
            </span>
          </div>
          <div class="md:p-5 md:text-right">
            <status-badge class="inline-block" :status="entry.current_status"></status-badge>
          </div>
        </div>
        <div class="w-full mb-2 px-5" v-if="entry.events.length">
          <!--          <preview-chart :chart-data="getChartData(entry)"></preview-chart>-->
          <div class="w-full h-2 flex mb-1">
            <div v-for="event in entry.events" :key="event.date"
                 :style="{width: (100 / entry.events.length) + '%'}"
                 :class="event.error_count > 0 ? 'bg-oh-red': 'bg-green-500'"
            >
            </div>
          </div>
          <div class="w-full flex italic">
            <div>
              {{ formatDate(entry.events[0].date) }}
            </div>
            <div class="flex-1 text-center">
              {{ formatDate(entry.events[Math.floor(entry.events.length / 2)].date) }}
            </div>
            <div>
              {{ formatDate(entry.last_checked_at) }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!monitors.length && !loading" class="text-center mt-5 p-10 w-full bg-blue-100 font-bold">
      {{ $t('monitoring.no-monitors') }}
      <div class="text-center mt-10">
        <oh-button type="primary" @click="addUpdateMonitor">
          <i class="fas fa-plus mr-1"></i> {{ $t('monitoring.add') }}
        </oh-button>
      </div>
    </div>
    <div v-if="loading" class="italic mt-10">
      {{ $t('main.loading') }}
    </div>
    <add-edit-monitor-modal
        :show="showModal" :monitor="selectedMonitor" :alerts="alerts"
        @close="showModal = false" @created="createdMonitor" @deleted="loadMonitors"
    ></add-edit-monitor-modal>
  </card>
</template>

<script>
import StatusBadge from "@/components/Monitoring/StatusBadge";
import Card from "@/components/Card";
import {api} from "@/api";
// import PreviewChart from "@/components/Monitoring/PreviewChart";
import moment from "moment";
import OhButton from "@/components/OhButton";
import AddEditMonitorModal from "@/components/Monitoring/AddEditMonitorModal";


const MONITOR_TEMPLATE = {
  id: null,
  name: null,
  uri: null,
  type: 'http',
  interval: 300,
  settings: {method: 'GET', port: 22},
  alert_ids: []
};

export default {
  props: {
    alerts: {required: true}
  },
  data() {
    return {
      monitors: [],
      loading: true,
      showModal: false,
      selectedMonitor: MONITOR_TEMPLATE,
      interval: null
    }
  },
  mounted() {
    this.loadMonitors();
    this.interval = setInterval(this.loadMonitors, 60000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    async loadMonitors() {
      let response = await api.get('monitoring/monitor');
      this.monitors = response.data;
      this.loading = false;
    },
    createdMonitor(monitor) {
      let matchingMonitors = this.monitors.filter(m => m.id === monitor.id);
      if (matchingMonitors.length) {
        // Replace existing monitor
        let index = this.monitors.indexOf(matchingMonitors[0]);
        this.monitors[index] = monitor;
      } else {
        // Push monitor
        this.monitors.push(monitor);
      }
    },
    addUpdateMonitor(monitor = null) {
      this.selectedMonitor = monitor || MONITOR_TEMPLATE;
      this.showModal = true;
    },
    formatDate(value) {
      return moment(value).format('ddd, HH:mm');
    },
    getChartData(monitor) {
      // create a copy
      let events = [...monitor.events];
      return {
        labels: events.map(e => moment(e.date).format('HH:mm')),
        series: [
          // events.map(e => e.response_time),
          events.map(e => e.error_count),
        ]
      }
    }
  },
  watch: {
    monitors() {
      this.$emit('monitorsUpdated', this.monitors);
    }
  },
  components: {
    AddEditMonitorModal,
    OhButton,
    // PreviewChart,
    StatusBadge,
    Card
  },
}
</script>

<style scoped>

</style>