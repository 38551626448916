<template>
  <main-layout>
    <template slot="title">
      {{ $t('not-found.title') }}
    </template>
    <div class="min-h-full">
      <div class="m-auto max-w-7xl">
        <card class="w-full">
          <template slot="title">{{ $t('not-found.title') }}</template>
          {{ $t('not-found.description') }}
        </card>
      </div>
      <img class="block m-auto w-36 mt-24" src="../assets/img/error.png">
    </div>
  </main-layout>
</template>

<script>

import MainLayout from "@/components/Layout/MainLayout";
import Card from "@/components/Card";

export default {
  components: {
    Card,
    MainLayout
  },
}
</script>
