<template>
  <div class="min-h-full">
    <div class="h-20 bg-oh-blue text-white px-2 md:px-6 py-6 flex sticky shadow-md">
      <div class="table font-bold text-lg md:text-xl flex-1">
        <div v-if="!loading">
          <slot name="title"></slot>
        </div>
        <span v-else>...</span>
      </div>
      <div class="hidden md:block text-3xl">
        <a href="#" title="Log Out" @click.prevent="logOut">
          <i class="fas fa-sign-out-alt"></i>
        </a>
      </div>
    </div>
    <div class="bg-gray-100 min-h-body">
      <div class="p-5 md:p-10 min-h-body-footer">
        <div v-if="!loading">
          <slot></slot>
        </div>
        <span v-else>{{ $t('main.loading') }}</span>
      </div>
      <div class="w-full text-right text-sm pr-4 pb-3 text-gray-500">
        <a href="https://www.oakhost.net/privacy" target="_blank" class="underline">{{ $t('footer.privacy') }}</a>
        <span v-if="locale === 'de'"> | <a href="https://www.oakhost.net/de/imprint" target="_blank" class="underline">Impressum</a></span>
        | <a href="https://www.oakhost.net/tos" target="_blank" class="underline">{{ $t('footer.tos') }}</a>
        | {{ $t('footer.copyright', [new Date().getFullYear()]) }}
      </div>
    </div>
  </div>
</template>

<script>
import {api} from "@/api";
import {session} from "@/sessionStore";
import i18n from "../../i18n";

export default {
  props: {
    loading: {
      default: false
    }
  },
  methods: {
    async logOut() {
      await api.post('user/logout');
      session.deleteToken();
      window.location.href = process.env.VUE_APP_WEBSITE_URL;
    }
  },
  computed: {
    locale() {
      return i18n.locale;
    }
  }
}
</script>

<style scoped>

</style>