<template>
  <div class="rounded shadow-inner w-20 px-2 py-1 text-xs text-white text-center font-bold" :class="{
    'bg-gray-500': status === 'new',
    'bg-green-500': status === 'up',
    'bg-red-500': status === 'down',
  }">
    {{ $t('monitoring.status.' + status)}}
  </div>
</template>

<script>
export default {
  props: {
    status: {required: true}
  },
}
</script>

<style scoped>

</style>