<template>
  <main-layout>
    <template slot="title">
      {{ $t('nav.user-management') }}
    </template>
    <div class="min-h-full">
      <div class="m-auto max-w-7xl">
        <card class="w-full">
          <template slot="title">
            {{ $t('user-management.headline') }}
          </template>
          <div v-if="users.length && !loading">
            <p>{{ $t('user-management.teaser') }}</p>
            <table class="text-left w-full mt-5">
              <thead>
              <tr class="border-b border-gray-400">
                <th class="px-3 py-3">{{ $t('user-management.name') }}</th>
                <th class="px-3 py-3">{{ $t('user-management.email') }}</th>
                <th class="px-3 py-3">{{ $t('user-management.status') }}</th>
                <th class="px-3 py-3"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(entry, key) in users" :key="key"
                  class="hover:bg-gray-100 border-b border-gray-200">
                <td class="px-3 py-3">
                  {{ entry.first_name }} {{ entry.last_name }}
                  <div class="ml-2 rounded shadow-inner px-2 py-1 text-xs text-white text-center font-bold inline-block bg-red-500"
                       v-if="entry.account_owner" :title="$t('user-management.account-owner-teaser')">
                    {{ $t('user-management.account-owner') }}
                  </div>
                  <div class="ml-2 rounded shadow-inner px-2 py-1 text-xs text-white text-center font-bold inline-block bg-green-500"
                       v-if="entry.tfa_enabled" :title="$t('user-management.tfa-enabled-teaser')">
                    {{ $t('user-management.tfa-enabled') }}
                  </div>
                </td>
                <td class="px-3 py-3">{{ entry.email }}</td>
                <td class="px-3 py-3">{{ formatStatus(entry.status) }}</td>
                <td class="px-3 py-3"><oh-button type="primary" @click="edit(entry)">View / Edit</oh-button></td>
              </tr>
              </tbody>
            </table>
            <div class="mt-8">
              <oh-button type="primary" @click="showCreateUserModal = true">
                <i class="fas fa-plus mr-2"></i>
                {{ $t('user-management.create-user') }}
              </oh-button>
            </div>
          </div>
          <div v-else>
            {{ $t('user-management.no-permission') }}
          </div>
        </card>
      </div>
    </div>

    <modal :show="showCreateUserModal" @close="showCreateUserModal = false">
      <template slot="title">{{ $t('user-management.create.title') }}</template>
      <section>
        <div class="mb-4">
          <p>{{ $t('user-management.create.teaser') }}</p>
        </div>
        <div class="lg:flex w-full lg:space-x-6 space-y-4 lg:space-y-0 mb-4">
          <oh-input :label="$t('account.first_name')" v-model="createForm.firstName" id="first_name"
                    class="flex-1"></oh-input>
          <oh-input :label="$t('account.last_name')" v-model="createForm.lastName" id="last_name"
                    class="flex-1"></oh-input>
        </div>
        <div class="w-full mb-4">
          <oh-input :label="$t('account.email.label')" v-model="createForm.email" id="email"></oh-input>
        </div>
        <div class="mb-4">
          <p>{{ $t('user-management.create.teaser-bottom') }}</p>
        </div>
      </section>
      <section class="flex mt-8">
        <div class="flex-1">
          <oh-button type="default" class="mr-4" @click="showCreateUserModal = false">{{ $t('user-management.create.cancel') }}</oh-button>
        </div>
        <div>
          <oh-button type="primary" @click="createUser()">{{ $t('user-management.create.apply') }}</oh-button>
        </div>
      </section>
    </modal>

    <modal :show="showModal" @close="showModal = false" v-if="editUser">
      <template slot="title">{{ $t('user-management.edit.title', [editUser.first_name, editUser.last_name]) }}</template>
      <section>
        <h2 class="text-xl mb-4">{{ $t('user-management.edit.permissions-title') }}</h2>
        <div class="flex mb-2">
          <oh-toggle v-model="editUserPermissions.allowUserManagement"
                     :title="$t('user-management.edit.user-management-notice')"
                     :readonly="true"
                     :label="$t('user-management.edit.permission-user-management')"></oh-toggle>
          {{ $t('user-management.edit.permission-user-management') }}
        </div>
        <div class="flex mb-2">
          <oh-toggle @click="editUserPermissions.accessAllServers = !editUserPermissions.accessAllServers"
                     :readonly="editUser.account_owner"
                     v-model="editUserPermissions.accessAllServers"
                     :label="$t('user-management.edit.permission-all-servers')"></oh-toggle>
          {{ $t('user-management.edit.permission-all-servers') }}
        </div>
        <div class="ml-12 mb-4" v-if="!editUserPermissions.accessAllServers">
          {{ $t('user-management.edit.permission-server') }}
          <div class="flex mt-2" v-for="(product, key) in products" :key="key">
            <oh-toggle @click="toggleServerAccess(product)"
                       :readonly="editUser.account_owner"
                       :value="editUserPermissions.accessSpecificServers.includes(product.id)"
                       :label="product.settings.name"></oh-toggle>
            {{ product.settings.name }}
          </div>
        </div>
        <div class="flex mb-2">
          <oh-toggle @click="editUserPermissions.manageSubscriptions = !editUserPermissions.manageSubscriptions"
                     :readonly="editUser.account_owner"
                     v-model="editUserPermissions.manageSubscriptions"
                     :label="$t('user-management.edit.manage-subscriptions')"></oh-toggle>
          {{ $t('user-management.edit.manage-subscriptions') }}
        </div>
      </section>
      <section class="mt-8" v-if="!editUser.account_owner">
        <h2 class="text-xl mb-4">{{ $t('user-management.edit.actions-title') }}</h2>
        <div class="flex">
          <oh-button type="danger-outline" class="mr-4" @click="confirmOwnerChange = true">{{ $t('user-management.edit.set-owner') }}</oh-button>
          <oh-button type="danger-outline" @click="sendPasswordResetEmail = true">{{ $t('user-management.edit.password-reset') }}</oh-button>
        </div>
      </section>
      <section class="flex mt-8" v-if="!editUser.account_owner">
        <div class="flex-1">
          <oh-button type="danger" class="mr-4" @click="confirmDeleteUser(editUser)">{{ $t('user-management.edit.delete') }}</oh-button>
        </div>
        <div>
          <oh-button type="primary" @click="applyPermissions(false)">{{ $t('user-management.edit.apply') }}</oh-button>
        </div>
      </section>
      <section v-else class="flex mt-8 italic">
        {{ $t('user-management.edit.edit-primary-notice') }}
      </section>
    </modal>

    <modal :show="sendPasswordResetEmail" :can-dismiss="false" v-if="editUser">
      <template slot="title">{{ $t('user-management.modal.confirm-title') }}</template>
      {{ $t('user-management.modal.confirm-reset', [editUser.first_name, editUser.last_name]) }}
      <section class="flex mt-8">
        <div class="flex-1">
          <oh-button type="default" class="mr-4" @click="sendPasswordResetEmail = false">{{ $t('user-management.cancel') }}</oh-button>
        </div>
        <div>
          <oh-button type="primary" @click="resetPassword(editUser)">{{ $t('user-management.confirm') }}</oh-button>
        </div>
      </section>
    </modal>
    <modal :show="confirmOwnerChange" :can-dismiss="false" v-if="editUser">
      <template slot="title">{{ $t('user-management.modal.confirm-title') }}</template>
      {{ $t('user-management.modal.confirm-owner-change', [editUser.first_name, editUser.last_name]) }}
      <p><strong>{{ $t('user-management.modal.confirm-owner-change-warning') }}</strong></p>
      <section class="flex mt-8">
        <div class="flex-1">
          <oh-button type="default" class="mr-4" @click="confirmOwnerChange = false">{{ $t('user-management.cancel') }}</oh-button>
        </div>
        <div>
          <oh-button type="primary" @click="applyPermissions(true)">{{ $t('user-management.confirm') }}</oh-button>
        </div>
      </section>
    </modal>
    <modal :show="showNoticeModal" :can-dismiss="false">
      <template slot="title">{{ $t('user-management.modal.notice-title') }}</template>
      {{ noticeMessage }}
      <div class="mt-8 text-center">
        <oh-button type="primary" @click="showNoticeModal = false">{{ $t('user-management.ok') }}</oh-button>
      </div>
    </modal>
  </main-layout>
</template>

<script>

import MainLayout from "@/components/Layout/MainLayout"
import Card from "@/components/Card"
import {api} from "@/api"
import {globalData} from "@/globalData"
import OhButton from "@/components/OhButton"
import Modal from "@/components/Modal"
import OhToggle from "@/components/OhToggle"
import OhInput from "@/components/OhInput";

export default {
  components: {
    OhInput,
    OhToggle,
    Modal,
    OhButton,
    Card,
    MainLayout
  },
  data() {
    return {
      loading: true,
      users: [],
      showModal: false,
      editUser: null,
      showCreateUserModal: false,
      createForm: {
        firstName: null,
        lastName: null,
        email: null
      },
      editUserPermissions: {
        accessAllServers: false,
        accessSpecificServers: [],
        manageSubscriptions: false,
        allowUserManagement: false
      },
      showNoticeModal: false,
      confirmOwnerChange: false,
      noticeMessage: '',
      sendPasswordResetEmail: false
    }
  },
  async mounted() {
    globalData.activeRoute = 'user-management'

    let response = await api.get('users')
    this.loading = false
    this.users = response.data
  },
  methods: {
    edit(user) {
      this.editUser = user
      if (this.editUser.account_owner) {
        this.editUserPermissions.allowUserManagement = true
        this.editUserPermissions.manageSubscriptions = true
        this.editUserPermissions.accessAllServers = true
      } else {
        this.editUserPermissions.allowUserManagement = false
        this.editUserPermissions.accessAllServers = false
        this.editUserPermissions.accessSpecificServers = []
        this.editUserPermissions.manageSubscriptions = false
        this.editUser.permissions.forEach(permission => {
          switch (permission.type) {
            case 'subscriptions':
              this.editUserPermissions.manageSubscriptions = true
              break;
            case 'servers':
              if (permission.data === '*') {
                this.editUserPermissions.accessAllServers = true
              } else {
                this.editUserPermissions.accessSpecificServers = permission.data.split(',')
              }
              break;
          }
        })
      }

      this.showModal = true
    },
    async createUser() {
      try {
        let response = await api.post('users', {
          email: this.createForm.email,
          first_name: this.createForm.firstName,
          last_name: this.createForm.lastName
        })
        this.users = response.data
        if (response.status === 200) {
          this.showCreateUserModal = false
          this.showNoticeModal = true
          this.noticeMessage = this.$t('user-management.create.success')
        }
      } catch (e) {
        this.showNoticeModal = true
        this.noticeMessage = this.$t('user-management.create.error') + e.response.data.message
      }
    },
    async confirmDeleteUser(user) {
      if (!confirm(this.$t('user-management.delete-confirm'))) {
        return;
      }

      let response = await api.delete('users/' + user.id);
      this.users = response.data

      this.showModal = false
      this.showNoticeModal = true
      this.noticeMessage = this.$t('user-management.delete-success')
    },
    async resetPassword(user) {
      this.sendPasswordResetEmail = false
      try {
        let response = await api.post('users/' + user.id + '/password_reset')
        if (response.status === 200) {
          this.noticeMessage = this.$t('user-management.modal.reset-success')
        }
      } catch (e) {
        this.noticeMessage = this.$t('user-management.modal.reset-error')
      }
      this.showNoticeModal = true
    },
    async applyPermissions(accountOwner) {
      this.showModal = false
      this.confirmOwnerChange = false

      try {
        let response = await api.put('users/' + this.editUser.id, {
          account_owner: accountOwner,
          permission_all_servers: this.editUserPermissions.accessAllServers,
          permission_specific_servers: this.editUserPermissions.accessSpecificServers,
          permission_manage_subscriptions: this.editUserPermissions.manageSubscriptions,
        })
        this.users = response.data
        if (response.status === 200) {
          this.noticeMessage = this.$t('user-management.modal.update-success')
        }
      } catch (e) {
        this.noticeMessage = this.$t('user-management.modal.update-error')
      }
      this.showNoticeModal = true
    },
    toggleServerAccess(product) {
      let index = this.editUserPermissions.accessSpecificServers.indexOf(product.id)
      if (index > -1) {
        this.editUserPermissions.accessSpecificServers.splice(index, 1)
      } else {
        this.editUserPermissions.accessSpecificServers.push(product.id)
      }
    },
    formatStatus(status) {
      switch (status) {
        case 'new':
        case 'verified':
          return this.$t('user-management.status-invited')
        case 'active':
          return this.$t('user-management.status-active')
        case 'disabled':
          return this.$t('user-management.status-disabled')
      }
    }
  },
  computed: {
    products() {
      return globalData.products
    }
  },
  watch: {
    showCreateUserModal() {
      if (this.showCreateUserModal === false) {
        this.createForm.firstName = null;
        this.createForm.lastName = null;
        this.createForm.email = null;
      }
    }
  }
}
</script>
